.template-page-404 {
  .template-content {
    > .background {
      min-height: calc(100vh - var(--template-header-height) - var(--footer-height) + 16px);
      padding: var(--container-padding-v) 0;
    }
  }

  .button {
    width: 184px;
    margin-top: 2em;

    @include media-breakpoint-up('sm') {
      width: 208px;
    }

    @include media-breakpoint-up('lg') {
      width: 248px;
    }

    @include media-breakpoint-up('xxl') {
      width: 264px;
    }
  }
}
