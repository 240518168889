.animation.envelope {
  transform: rotate(-5deg);
  transform-origin: 50% 100%;

  .animation {
    &-envelope {
      //
    }

    &-stamps {
      --delay: 0;

      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      clip-path: polygon(-20% -100%, -20% 30%, 0% 30%, 12% 31.75%, 50% 56%, 88% 31.75%, 100% 30%, 120% 30%, 120% -100%);

      @keyframes stamps {
        100% {
          transform: translateY(var(--stamp-position));
        }
      }

      span {
        display: block;
        position: absolute;
        z-index: 2;
        transform: translate(-50%, -50%);

        img {
          display: block;
          max-width: 100%;
          animation: stamps 1s paused;
          animation-delay: var(--delay);
        }

        /** Belgium 15 */
        &:nth-child(1) {
          --stamp-position: -530%;

          width: 30%;
          bottom: 8%;
          right: 5%;
          z-index: 10;
          transform: translate(-50%, -50%) rotate(-8deg);
        }

        /** Bhutan 50CH */
        &:nth-child(2) {
          --stamp-position: -120%;

          width: 21.5%;
          bottom: 8%;
          right: -7%;
          transform: translate(-50%, -50%) rotate(-7deg);
        }

        /** Bolivia 0.10$ */
        &:nth-child(3) {
          --stamp-position: -400%;

          width: 26.25%;
          bottom: 8%;
          left: 27%;
          transform: translate(-50%, -50%) rotate(10deg);
        }

        /** Cambodge 10R */
        &:nth-child(4) {
          --stamp-position: -340%;

          width: 21.75%;
          bottom: -5%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(15deg);
        }

        /** Cambodge 3R */
        &:nth-child(5) {
          --stamp-position: -190%;

          width: 21.75%;
          bottom: -10%;
          right: 22%;
          transform: translate(-50%, -50%) rotate(-19deg);
        }

        /** Canada 37 */
        &:nth-child(6) {
          --stamp-position: -105%;

          width: 20.25%;
          bottom: 21%;
          left: 12.5%;
          transform: translate(-50%, -50%) rotate(10deg);
        }

        /** Suomi Finland 1,60 */
        &:nth-child(7) {
          --stamp-position: -350%;

          width: 21%;
          bottom: -5%;
          right: 60%;
          transform: translate(-50%, -50%) rotate(-6deg);
        }

        /** Kampuchea 1R */
        &:nth-child(8) {
          --stamp-position: -160%;

          width: 30.75%;
          bottom: 0%;
          right: 10%;
          transform: translate(-50%, -50%) rotate(-15deg);
        }

        /** Magyar 4Ft */
        &:nth-child(9) {
          --stamp-position: -310%;

          width: 20.75%;
          bottom: 0%;
          left: 55%;
          transform: translate(-50%, -50%) rotate(27deg);
        }

        /** Magyar 2Ft */
        &:nth-child(10) {
          --stamp-position: -280%;

          width: 28.5%;
          bottom: 10%;
          left: 23%;
          z-index: 1;
          transform: translate(-50%, -50%) rotate(38deg);
        }

        /** Mongolia 5 */
        &:nth-child(11) {
          --stamp-position: -540%;

          width: 26.5%;
          bottom: 10%;
          left: 40%;
          transform: translate(-50%, -50%) rotate(-12deg);
        }
      }
    }
  }
}
