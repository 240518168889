.section.novelties {
  position: relative;
  padding: calc(var(--container-padding-v) / 2) 0;

  .section {
    &-title {
      margin: 0.5em 0;
    }

    &-content {
      position: relative;
      padding: 0;
    }

    &-footer {
      padding: 0;
      text-align: right;

      .button {
        width: 208px;
        margin-top: 2em;

        @include media-breakpoint-up('sm') {
          width: 232px;
        }

        @include media-breakpoint-up('lg') {
          width: 272px;
        }

        @include media-breakpoint-up('xxl') {
          width: 288px;
        }
      }
    }
  }

  .novelties {
    &-slider.slider {
      padding: 72px 0 calc(var(--container-padding-v) / 2) 0;
      position: relative;
      overflow: hidden;

      @include media-breakpoint-up('lg') {
        padding-top: 132px;
      }

      @include media-breakpoint-up('xxxl') {
        padding-bottom: calc(var(--container-padding-v) * 0.5);
      }

      @include media-breakpoint-up('huge') {
        padding-bottom: calc(var(--container-padding-v) * 0.75);
      }

      .slider {
        &-nav {
          display: flex;
          justify-content: space-between;
          transform: translateY(-100%);
          overflow: hidden;

          @include media-breakpoint-up('lg') {
            position: absolute;
            width: 100%;
            transform: translateY(-150%);
          }

          /** Prev / next buttons */
          .button {
            width: 72px;
            height: 56px;
            font-size: 1.125em;
            line-height: 54px;

            @include media-breakpoint-up('sm') {
              width: 88px;
              height: 72px;
              font-size: 1.3125em;
              line-height: 72px;
            }

            @include media-breakpoint-up('lg') {
              width: 104px;
              height: 88px;
              font-size: 1.5em;
              line-height: 88px;
            }

            @include media-breakpoint-up('xxl') {
              font-size: 1.6875em;
            }

            &:first-child {
              margin-left: -8px;

              &.is-disabled {
                transform: translateX(-100%);
              }

              .icon {
                transform: translateX(4px);
              }
            }

            &:last-child {
              margin-right: -8px;

              &.is-disabled {
                transform: translateX(100%);
              }

              .icon {
                transform: translateX(-4px);
              }
            }

            @media (prefers-reduced-motion: no-preference) {
              transition: transform 0.25s ease-out, opacity 0.25s ease-out;

              .icon {
                transition: transform 0.25s ease-out;
              }

              &:not(.is-disabled):hover,
              &:not(.is-disabled):focus,
              &:not(.is-disabled):focus-within,
              &:not(.is-disabled):focus-visible {
                .icon {
                  transform: translateX(0);
                }
              }
            }
          }
        }

        &-track {
          /**
           * The min-height of this element represents the width of a novelty article * the aspect ratio
           */
          min-height: calc(540px * 48.0666 / 100);
          width: 100%;
          transform-origin: 50% 2600%;

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.25s ease-out;
          }

          @include media-breakpoint-up('xxs') {
            min-height: calc(640px * 48.0666 / 100);
          }

          @include media-breakpoint-up('xs') {
            min-height: calc((100vw - 2em) * 48.0666 / 100);
          }

          @include media-breakpoint-up('md') {
            min-height: calc((66.6666vw - 2em) * 48.0666 / 100);
          }

          @include media-breakpoint-up('xl') {
            min-height: calc((50vw - 2em) * 48.0666 / 100);
          }

          @include media-breakpoint-up('xxxl') {
            min-height: calc((40vw - 2em) * 48.0666 / 100);
          }

          @include media-breakpoint-up('huge') {
            min-height: calc((33.3333vw - 2em) * 48.0666 / 100);
          }

          /** Active slides */
          @for $i from 1 through 12 {
            &[data-active-slide='#{$i}'] {
              transform: rotate(#{0.05deg - 2.45deg * ($i - 1)});

              @include media-breakpoint-up('xs') {
                transform: rotate(#{-0.05deg - 2.45deg * ($i - 1)});
              }

              @include media-breakpoint-up('md') {
                transform: rotate(#{-1.25deg - 2.45deg * ($i - 1)});
              }

              @include media-breakpoint-up('xl') {
                transform: rotate(#{-2.5deg - 2.45deg * ($i - 1)});
              }

              @include media-breakpoint-up('xxxl') {
                transform: rotate(#{-3.7deg - 2.45deg * ($i - 1)});
              }

              @include media-breakpoint-up('huge') {
                transform: rotate(#{-4.95deg - 2.45deg * ($i - 1)});
              }
            }
          }
        }

        &-item {
          position: absolute;
          z-index: 1;
          left: 270px;
          transform: translateX(-50%);
          transform-origin: 50% 2600%;

          @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
              z-index: #{100 - $i};
              transform: translateX(-50%) rotate(#{2.45deg * ($i - 1)}) translateX(-50%);
            }
          }

          @include media-breakpoint-up('xxs') {
            left: 320px;
          }

          @include media-breakpoint-up('xs') {
            left: 50%;
          }

          /** Postmark position & rotation */
          &:nth-child(3n + 1) .article-header .datetime {
            top: 80%;
          }

          &:nth-child(3n + 2) .article-header .datetime {
            top: 60%;
          }

          &:nth-child(3n + 3) .article-header .datetime {
            top: 70%;
          }

          &:nth-child(odd) .article-header .datetime span {
            transform: rotate(-11deg);
          }

          &:nth-child(even) .article-header .datetime span {
            transform: rotate(5deg);
          }
        }
      }
    }
  }
}
