.article.event {
  display: flex;
  position: relative;
  z-index: 1;
  width: 77.5vw;
  background-color: color('cream');
  font-size: 0.9em;

  @include media-breakpoint-up('xs') {
    font-size: 1em;
  }

  @include media-breakpoint-up('md') {
    width: 57.5vw;
  }

  @include media-breakpoint-up('lg') {
    width: 37.5vw;
    font-size: 0.9167em;
  }

  @include media-breakpoint-up('xl') {
    font-size: 1em;
  }

  /** Aspect ratio */
  &::before {
    content: '';
    display: block;
    padding-top: 69.697%;
  }

  .article {
    $header-height: 6.25em;

    &-wrap {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: #{$header-height} auto calc(40px + 1em);
      width: 100%;
      background-color: color('cream');

      @include media-breakpoint-up('lg') {
        grid-template-rows: #{$header-height} auto calc(48px + 1em);
      }

      @include media-breakpoint-up('xxl') {
        grid-template-rows: #{$header-height} auto calc(48px + 2em);
      }

      /** Shadow */
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: 25px;
        bottom: 15px;
        left: 25px;
        z-index: -1;
        box-shadow: 0 0 70px -40px color('black');
      }
    }

    &-header {
      display: flex;
      padding: 1em;
      height: $header-height;
      overflow: hidden;

      @include media-breakpoint-up('xxl') {
        padding-left: 2em;
      }

      .datetime {
        //
      }
    }

    &-title {
      flex-grow: 1;
      align-self: flex-end;
      padding-right: 2em;
      margin: 0;
      color: color('secondary');
      font-family: $font-family-narrow;
      font-size: 1em;
      font-weight: $font-weight-medium;
      line-height: $line-height-base;

      @include media-breakpoint-up('xxs') {
        font-size: 1.0769em;
      }
    }

    &-content {
      @include scrollbar;

      padding: 0 1em;
      min-height: calc(54.0152vw - #{$header-height} - 40px - 2em);
      max-height: calc(111.1957vw - #{$header-height} - 40px - 2em);
      margin-bottom: 1em;
      overflow: scroll;
      overflow-x: hidden;

      @include media-breakpoint-up('xxs') {
        min-height: auto;
        max-height: none;
        height: calc(54.0152vw - #{$header-height} - 40px - 2em);
      }

      @include media-breakpoint-up('md') {
        height: calc(40.0758vw - #{$header-height} - 40px - 2em);
      }

      @include media-breakpoint-up('lg') {
        height: calc(26.1364vw - #{$header-height} - 48px - 2em);
      }

      @include media-breakpoint-up('xxl') {
        height: calc(26.1364vw - #{$header-height} - 48px - 3em);
        padding: 1em 2em;
      }
    }

    &-links {
      padding: 0 1em;

      @include media-breakpoint-up('xxl') {
        padding: 0 2em;
      }
    }

    &-images {
      position: absolute;
      top: 5%;
      right: 12%;
      left: 12%;
      z-index: -1;
      width: 76%;
      transform: translate(2%, -95%) rotate(-13deg);
      transform-origin: right bottom;

      /** Shadow */
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        z-index: -1;
        box-shadow: 0 0 70px -30px color('black');
      }
    }
  }

  .enum-item:nth-child(n + 2) & {
    .article-images {
      right: auto;
      top: 15%;
      left: 45%;
      transform: rotate(-3deg);
      transform-origin: center;
    }
  }

  .enum-item:nth-child(n + 3) & {
    .article-images {
      transform: rotate(4deg);
    }
  }

  @include media-breakpoint-up('lg') {
    .enum-item:nth-child(3n - 2) & {
      .article-images {
        top: 5%;
        right: 12%;
        left: 12%;
        transform: translate(2%, -95%) rotate(-13deg);
        transform-origin: right bottom;
      }
    }

    .enum-item:nth-child(6n + 4) & {
      .article-images {
        transform: translate(-2%, -95%) rotate(13deg);
        transform-origin: left bottom;
      }
    }

    .enum-item:nth-child(6n + 7) & {
      .article-images {
        transform: translate(2%, -95%) rotate(-13deg);
        transform-origin: right bottom;
      }
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .article-images {
      transition: transform 0.25s ease-out;
    }

    .enum-item &:hover {
      .article-images {
        transform: translate(2%, -95%) rotate(-10deg);
      }
    }

    .enum-item:nth-child(n + 2) & {
      &:hover {
        .article-images {
          transform: translateX(5%) rotate(-1deg);
        }
      }
    }

    @include media-breakpoint-up('lg') {
      .enum-item:nth-child(3n - 1) &,
      .enum-item:nth-child(3n) & {
        &:hover {
          .article-images {
            transform: translateX(5%) rotate(-1deg);
          }
        }
      }

      .enum-item:nth-child(6n + 4) & {
        &:hover {
          .article-images {
            transform: translate(-2%, -95%) rotate(10deg);
          }
        }
      }

      .enum-item:nth-child(6n + 7) & {
        &:hover {
          .article-images {
            transform: translate(2%, -95%) rotate(-10deg);
          }
        }
      }
    }
  }

  .event {
    &-text {
      font-size: 0.923em;
    }

    &-image {
      //
    }

    &-button {
      width: 136px;

      @include media-breakpoint-up('sm') {
        width: 160px;
      }

      @include media-breakpoint-up('lg') {
        width: 176px;
      }

      @include media-breakpoint-up('xxl') {
        width: 192px;
      }
    }
  }
}
