/**
 * Styled scrollbar
 */
@mixin scrollbar($thumb-color: color('teal'), $background-color: transparent) {
  scrollbar-color: $thumb-color $background-color;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
  }
}
