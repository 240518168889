.section.newsearch {
  position: relative;
  z-index: 1;

  @include media-breakpoint-up('sm') {
    &::before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      min-height: calc(100vh - var(--template-header-height) - var(--footer-height) + 24px);
      // prettier-ignore
      background: url('../../../images/envelope-magnifier-640.png') no-repeat left var(--container-padding-v) / calc(var(--container-padding-h) * 3) auto;
      mix-blend-mode: darken;

      @include media-breakpoint-up('lg') {
        background-image: url('../../../images/envelope-magnifier-960.png');
      }

      @include media-breakpoint-up('xxl') {
        background-image: url('../../../images/envelope-magnifier-1440.png');
        background-position: left calc(var(--container-padding-v) / 2);
      }

      @include media-breakpoint-up('huge') {
        // background-image: url('../../../images/envelope-magnifier-1920.png');
      }
    }

    .container {
      padding-left: calc(var(--container-padding-h) * 4);
    }
  }

  .section {
    &-title {
      &::before {
        @include icon('\1f50d', 1em, color('secondary'));

        margin-right: 1em;
      }
    }

    &-content {
      position: relative;
      overflow: hidden;
      padding: 0.75em 1em;
      background-color: rgba(color('white', false), 0.5);
      box-shadow: 0 0 60px -60px color('cedar');

      @include media-breakpoint-up('md') {
        display: flex;
        padding: 1.75em 2em;
      }

      &.is-loading {
        /* stylelint-disable-next-line declaration-no-important */
        min-height: 9.75rem !important;
      }

      .loader {
        padding-top: 2.5em;
      }

      strong {
        display: inline-block;
        font-family: $font-family-headings;
        font-weight: $font-weight-normal;
        line-height: $line-height-sm;
        transform: translateY(0.0192em);
      }

      p.auth-error {
        margin: 0;
        color: color('ruby');
        font-weight: $font-weight-semibold;
      }

      &-text {
        @include media-breakpoint-up('md') {
          padding-right: 2em;
        }
      }

      &-form {
        margin: 2em 0 1em 0;

        @include media-breakpoint-up('md') {
          margin: 0;
        }

        .form.login {
          margin: 0 auto;
        }
      }
    }
  }
}
