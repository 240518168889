.nav.footer-nav {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  height: var(--footer-nav-height);
  overflow: hidden;
  padding: calc(var(--container-padding-v) / 2) 0;
  background-color: rgba(color('white', false), 0.8);
  font-family: $font-family-headings;

  --button-width: 72px;

  @include media-breakpoint-up('sm') {
    --button-width: 88px;
  }

  @include media-breakpoint-up('lg') {
    --button-width: 104px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;

    @include hatched-background;
  }

  .button {
    width: var(--button-width);
    min-width: var(--button-width);
    height: 56px;
    margin: 1em 0;
    vertical-align: middle;
    font-size: 1.125em;
    line-height: 54px;

    @include media-breakpoint-up('sm') {
      height: 72px;
      font-size: 1.3125em;
      line-height: 72px;
    }

    @include media-breakpoint-up('lg') {
      height: 88px;
      font-size: 1.5em;
      line-height: 88px;
    }

    @include media-breakpoint-up('xxl') {
      font-size: 1.6875em;
    }

    &-label {
      pointer-events: none;
      display: inline-block;
      position: absolute;
      top: calc(100% + 1em);
      width: 50vw;
      font-size: 1rem;
      line-height: $line-height-base;

      @include media-breakpoint-up('xs') {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .datetime {
    position: absolute;
    top: 1.5em;
    z-index: -1;

    @include media-breakpoint-up('sm') {
      top: calc(0px - var(--container-padding-v) / 8);
    }

    @include media-breakpoint-up('lg') {
      top: calc(0px - var(--container-padding-v) / 4);
    }

    @include media-breakpoint-up('xxl') {
      top: calc(0px - var(--container-padding-v) / 3);
    }
  }

  .nav-section {
    position: relative;
    z-index: 1;
    max-width: 50%;

    @include media-breakpoint-up('xs') {
      display: flex;
      align-items: center;
    }

    &.l-prev {
      .button {
        margin-left: -8px;

        &-label {
          left: 0;
          padding-left: 1.5em;
          padding-right: calc(var(--container-padding-h) / 2);
          text-align: left;

          @include media-breakpoint-up('xs') {
            padding-left: calc(var(--button-width) + 1em);
          }
        }
      }

      .datetime {
        left: 70px;

        @include media-breakpoint-up('xs') {
          left: 1em;
        }

        @include media-breakpoint-up('md') {
          left: 2em;
        }

        span {
          transform: rotate(5deg);
        }
      }
    }

    &.l-next {
      text-align: right;

      .button {
        margin-right: -8px;

        &-label {
          right: 0;
          padding-left: calc(var(--container-padding-h) / 2);
          padding-right: 1.5em;
          text-align: right;

          @include media-breakpoint-up('xs') {
            padding-right: calc(var(--button-width) + 1em);
          }
        }
      }

      .datetime {
        right: 72px;

        @include media-breakpoint-up('xs') {
          right: 1em;
        }

        @include media-breakpoint-up('md') {
          right: 2em;
        }
      }
    }
  }
}
