.template-club-register {
  .section.flexible + .section.contactform {
    padding-top: 0;

    &::before {
      @include hatched-background;

      content: '';
      margin-bottom: var(--container-padding-v);
    }
  }

  .section.contactform {
    @include pale-gradient-background('teal');
  }
}
