.form.login {
  @include stamp-background(color('cream'), color('tortilla'));

  width: 272px;
  height: 168px;
  padding: 12px 13px 12px 12px;
  margin: auto;
  font-size: 0.9167em;

  @include media-breakpoint-up('md') {
    font-size: 0.8462em;
  }

  @include media-breakpoint-up('lg') {
    font-size: 0.8077em;
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: height 0.25s ease-out;
  }

  &.has-status {
    height: 224px;
  }

  &.has-errors {
    height: 240px;
  }

  .form {
    &-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 0 2px;
      border: 2px solid color('cedar');
    }

    &-section {
      display: flex;
      flex-direction: column;
      height: 50%;

      &:first-child {
        max-height: 3.75em;
      }

      &:last-child {
        // min-height: 50%;
        flex-grow: 1;
      }
    }

    &-field {
      position: relative;
      height: 50%;

      label {
        @include visually-hidden;
      }

      &.text {
        input {
          height: 100%;
          border-bottom: 1px solid rgba(color('tortilla', false), 0.3);
          color: color('cedar');
          font-weight: $font-weight-normal;
        }

        .form-field-control {
          display: flex;
          height: 100%;
        }
      }

      &-error {
        position: absolute;
        z-index: 1;
        right: 1px;
        bottom: calc(1em + 1px);
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        font-size: 0.6923em;
        color: color('ruby');
        transform: translateY(calc(100% - 0.1em));

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-out 0s 1 alternate backwards;
        }
      }
    }

    &-status,
    &-error {
      margin: 0;
      padding: 0 0.25em;
      font-size: 0.9167em;
      font-weight: $font-weight-semibold;
      line-height: $line-height-sm;
      text-align: center;

      @media (prefers-reduced-motion: no-preference) {
        animation: appear 0.25s ease-out 0s 1 alternate backwards;
      }

      &:not(:empty) {
        padding-top: 0.5em;
      }
    }

    &-status {
      height: 40%;
      color: color('teal');

      &:not(:empty) + .form-submit {
        height: 60%;
      }
    }

    &-error {
      height: 50%;
      color: color('ruby');

      &:not(:empty) + .form-submit {
        height: 50%;
      }
    }

    &-submit {
      display: flex;
      height: 100%;
      margin: 2px 0;

      .button {
        // @include cursor('fingers-crossed');

        width: 100%;
        height: 100%;
        font-size: 1.2381em;
        line-height: 1;
      }
    }
  }
}
