.loader {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  max-height: 100vh;
  padding-top: 6em;
  z-index: 10;
  animation: appear 0.5s ease-in; // Must match the delay in useNavigateWithLoader.js
  transition: opacity 0.35s ease 0.25s; // The total must match the delay in Loader.jsx

  &.app-loader {
    align-items: center;
    position: fixed;
    z-index: 110;
    height: auto;
    padding-top: 0;

    .loader-content {
      width: 320px;
      height: 160px;
      font-size: 1.25em;
      line-height: 145px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -100vh;
    right: -100vw;
    bottom: -100vh;
    left: -100vw;
    background: radial-gradient(lighten(color('tortilla', false), 10%) 0%, darken(color('tortilla', false), 10%) 200%);
    transform: rotate(-10deg);
  }

  &-content {
    width: 256px;
    height: 112px;
    font-family: $font-family-headings;
    font-size: 1em;
    font-weight: $font-weight-normal;
    line-height: 110px;
    text-align: center;

    @include stamp-background-large(color('cream'), color('tortilla'));
  }

  &-dot {
    &:nth-of-type(1) {
      animation: appear 0.8s ease-in 0s infinite backwards;
    }

    &:nth-of-type(2) {
      animation: appear 0.8s ease-in 0.2s infinite backwards;
    }

    &:nth-of-type(3) {
      animation: appear 0.8s ease-in 0.4s infinite backwards;
    }
  }

  &.c-ruby::before {
    background: radial-gradient(lighten(color('ruby', false), 10%) 0%, darken(color('ruby', false), 10%) 200%);
  }

  &.c-teal::before {
    background: radial-gradient(lighten(color('teal', false), 10%) 0%, darken(color('teal', false), 10%) 200%);
  }

  &.c-peacock::before {
    background: radial-gradient(lighten(color('peacock', false), 10%) 0%, darken(color('peacock', false), 10%) 200%);
  }

  &.c-gold::before {
    background: radial-gradient(lighten(color('gold', false), 10%) 0%, darken(color('gold', false), 10%) 200%);
  }

  &.c-cedar::before {
    background: radial-gradient(lighten(color('cedar', false), 10%) 0%, darken(color('cedar', false), 10%) 200%);
  }

  &.c-tortilla::before {
    background: radial-gradient(lighten(color('tortilla', false), 10%) 0%, darken(color('tortilla', false), 10%) 200%);
  }

  &.c-sand::before {
    background: radial-gradient(lighten(color('sand', false), 1%) 0%, darken(color('sand', false), 10%) 200%);
  }

  &.c-cream::before {
    background: radial-gradient(color('white', false) 0%, darken(color('cream', false), 10%) 200%);
  }

  &.is-loaded {
    opacity: 0;
  }
}
