.layout.footer {
  position: relative;
  z-index: 11;
  margin-top: -8px;
  overflow: hidden;
  font-family: $font-family-headings;
  font-size: 0.8462rem;
  font-weight: $font-weight-normal;
  color: color('white');

  .background {
    display: flex;
    align-items: center;
    height: var(--footer-height);
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: -8px;
  }

  .footer {
    &-content.content {
      width: 100%;
      margin-top: -4px;
      text-align: center;

      @include media-breakpoint-up('sm') {
        display: flex;
        justify-content: space-between;
      }

      .content {
        &-section {
          padding: 0.25em;

          @include media-breakpoint-up('sm') {
            padding: 0.25em 0.75em;
          }

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }

    &-button {
      color: color('white');
      white-space: nowrap;

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        color: color('sand');
      }
    }

    &-author {
      @include cursor('fingers-up');
    }
  }

  .beegreen {
    margin-left: 0.1em;
    height: 1em;
    vertical-align: middle;

    @media (prefers-reduced-motion: no-preference) {
      .bee .outline {
        transition: fill 0.25s ease-out;
      }
    }
  }

  .link.footer-author {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      .beegreen {
        .bee .outline {
          fill: #72be1e;
        }
      }
    }
  }
}
