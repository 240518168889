/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */
.alignnone {
  max-width: 100%;
  height: auto;
  margin-left: 0;
  margin-right: 0;
}

.aligncenter,
.alignleft,
.alignright {
  display: block;
  height: auto;

  &.size-thumbnail {
    max-width: 15vw;
  }

  &.size-medium {
    max-width: 30vw;
  }

  &.size-full {
    //
  }
}

.aligncenter {
  max-width: 30vw;
  margin: 0.5em auto calc(var(--container-padding-v) / 4) auto;
}

.alignleft,
.alignright {
  margin: 0.3333em auto calc(var(--container-padding-v) / 4) auto;
}

.alignleft.size-thumbnail {
  margin-right: calc(var(--container-padding-h) / 2);
  float: left;
}

.alignright.size-thumbnail {
  margin-left: calc(var(--container-padding-h) / 2);
  float: right;
}

@include media-breakpoint-up('xs') {
  .alignleft {
    margin-right: calc(var(--container-padding-h) / 2);
    float: left;
  }

  .alignright {
    margin-left: calc(var(--container-padding-h) / 2);
    float: right;
  }
}
