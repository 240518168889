/**
 * Novelties cards width, font size and line height
 *
 * `$novelty-card-width` and `$novelty-card-font-size` should have the same breakpoints
 */
$novelty-card-width: (
  'null': 540px,
  'xxs': 640px,
  'xs': calc(100vw - 2em),
  'md': calc(66.6666vw - 2em),
  'xl': calc(50vw - 2em),
  'xxxl': calc(40vw - 2em),
  'huge': calc(33.3333vw - 2em),
);
$novelty-card-font-size: (
  'null': 14px,
  'xxs': 17px,
  'xs': 2.5vw,
  'md': 1.6666vw,
  'xl': 1.25vw,
  'xxxl': 1vw,
  'huge': 0.8333vw,
);
$novelty-card-line-height: (
  'null': 32px,
  'xxs': 38px,
  'xs': 6vw,
  'md': 4vw,
  'xl': 3vw,
  'xxxl': 2.5vw,
  'huge': 2vw,
);

/**
 * Novelty details
 */
.article.novelty-details {
  display: flex;
  flex-wrap: wrap;
  padding: var(--container-padding-v) 0;

  @include media-breakpoint-up('xxl') {
    flex-wrap: nowrap;
  }

  .article {
    &.novelty-card {
      background-color: color('white');
    }

    &-section {
      padding: 0 calc(var(--container-padding-h));

      &:not(:last-child) {
        margin-bottom: var(--container-padding-v);

        @include media-breakpoint-up('xxl') {
          margin-bottom: 0;
        }
      }

      &:nth-last-child(2) {
        @include media-breakpoint-up('lg') {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-up('xxl') {
        padding: 0 calc(var(--container-padding-h) / 4);
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: var(--container-padding-h);
      }

      &.l-card {
        width: 540px;
        transform: rotate(-2deg) translateX(-100%);
        transform-origin: left top;

        @each $breakpoint, $value in $novelty-card-width {
          @if $breakpoint == 'null' {
            width: calc(#{$value} / 2);
          } @else {
            @include media-breakpoint-up($breakpoint) {
              width: calc(#{$value} / 2);
            }
          }
        }
      }

      &.l-details {
        flex-grow: 1;
        width: 100%;

        @include media-breakpoint-up('xs') {
          width: 50%;
          padding-left: calc(var(--container-padding-h) * 4);
        }

        @include media-breakpoint-up('sm') {
          padding-left: calc(var(--container-padding-h) * 2);
        }

        @include media-breakpoint-up('xl') {
          flex-grow: 0;
          width: 36.6666%;
          padding-left: var(--container-padding-h);
        }
      }

      &.l-collection {
        width: 100%;

        @include media-breakpoint-up('lg') {
          width: 60%;
          padding-right: calc(var(--container-padding-h) / 4);
        }

        @include media-breakpoint-up('xl') {
          flex-grow: 1;
          order: 4;
          padding-right: var(--container-padding-h);
        }

        @include media-breakpoint-up('xxl') {
          order: 3;
          padding-right: calc(var(--container-padding-h) / 4);
        }
      }

      &.l-leaflet {
        width: 100%;

        @include media-breakpoint-up('lg') {
          width: 40%;
          padding-left: calc(var(--container-padding-h) / 4);
        }

        @include media-breakpoint-up('xl') {
          order: 3;
          width: 35%;
          flex-grow: 1;
          padding-left: 0;
          margin-bottom: var(--container-padding-v);
        }

        @include media-breakpoint-up('xxl') {
          order: 4;
          padding-left: calc(var(--container-padding-h) / 4);
          margin-bottom: 0;
        }
      }

      &:not(.l-card) {
        p {
          font-family: $font-family-headings;
          font-size: 0.8462em;
          font-weight: $font-weight-normal;
          color: color('secondary');

          .icon {
            margin-right: 0.5em;
            font-size: 0.8462rem;
          }
        }
      }

      &-title {
        color: color('teal');
        font-size: 0.8462em;
        white-space: nowrap;

        &::after {
          content: '';
          display: block;
          margin-top: 0.1em;
          border-bottom: 1px solid color('teal');
        }
      }
    }
  }

  .novelty-collection {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5em;

    .image-stamp {
      max-width: calc(33.3333% - 0.35em);
      max-height: 9.25em;
    }
  }

  .novelty-leaflet {
    .image-leaflet {
      @include media-breakpoint-up('md') {
        max-width: 80%;
      }

      @include media-breakpoint-up('lg') {
        max-width: 100%;
      }

      @include media-breakpoint-up('xxl') {
        max-width: 15em;
      }

      @include media-breakpoint-up('xxxl') {
        max-width: 16em;
      }
    }
  }
}

/**
 * Novelty card
 */
.article.novelty-card {
  display: flex;
  position: relative;
  margin: 0 auto;
  background-color: color('cream');

  @each $breakpoint, $value in $novelty-card-width {
    @if $breakpoint == 'null' {
      width: #{$value};
      font-size: map-get($novelty-card-font-size, $breakpoint);
    } @else {
      @include media-breakpoint-up($breakpoint) {
        width: #{$value};
        font-size: map-get($novelty-card-font-size, $breakpoint);
      }
    }
  }

  /** Aspect ratio */
  &::before {
    content: '';
    display: block;
    padding-top: 48.066%;
  }

  /** Shadow */
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: -1;
    box-shadow: 0 0 45px -30px color('black');
  }

  .article {
    &-wrap {
      flex-grow: 1;
      position: relative;
      z-index: 5;
      padding: 1em;
    }

    &-header {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-left: 1em;
      margin-left: 50%;
      border-left: 1px solid color('tortilla');
      color: color('secondary');

      .datetime {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 1.174em;
        transform: translate(-102.5%, -50%);
      }
    }

    &-headlines {
      @each $breakpoint, $value in $novelty-card-line-height {
        @if $breakpoint == 'null' {
          --line-height: #{$value};
        } @else {
          @include media-breakpoint-up($breakpoint) {
            --line-height: #{$value};
          }
        }
      }

      order: 2;
      padding: 0 1em;
      height: calc(var(--line-height) * 3);
      line-height: var(--line-height);
    }

    &-title,
    &-subtitle {
      margin-bottom: 0;
      font-family: $font-family-narrow;
      font-weight: $font-weight-medium;
      line-height: var(--line-height);
    }

    &-title {
      position: relative;
      min-height: calc(var(--line-height) * 2);
      font-size: 1.174em;

      /** Lines behind title */
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(var(--line-height) - 0.125em);
        width: 100%;
        height: var(--line-height);
        border-top: 1px solid color('tortilla');
        border-bottom: 1px solid color('tortilla');

        @include media-breakpoint-up('md') {
          top: calc(var(--line-height) - 0.25em);
        }
      }
    }

    &-subtitle {
      /** Lines behind text */
      &::after {
        content: '';
        display: block;
        height: 6px;
        border-top: 1px solid color('tortilla');
        border-bottom: 3px solid color('tortilla');
        transform: translateY(-0.375em);

        @include media-breakpoint-up('md') {
          transform: translateY(-0.5em);
        }
      }
    }

    &-images {
      order: 1;
      min-height: 50%;
      text-align: right;
    }
  }

  .novelty-card {
    &-featured-image {
      display: inline-block;
      position: relative;

      &:not(.has-images) .datetime {
        /* stylelint-disable-next-line declaration-no-important -- Override Postmark position variants from sections/_novelties.scss */
        top: 1.5rem !important;
        right: -2em;
        bottom: auto;
        left: auto;
      }

      .image-stamp {
        vertical-align: top;
        max-width: 7.5em;
        max-height: 7.5em;
      }
    }

    &-overlay-button {
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;

      .icon {
        font-size: 1.4em;
      }
    }
  }
}

/**
 * Novelty card overlay
 */
.article.novelty-overlay {
  display: flex;
  position: absolute;
  top: 1em;
  left: 50%;
  z-index: 100;
  width: calc(100% - 1em);
  background-color: color('cream');
  font-size: 15px;
  transform: translateX(-50%);

  @include media-breakpoint-up('xxs') {
    font-size: 18px;
  }

  @include media-breakpoint-up('md') {
    font-size: 20px;

    /** Aspect ratio */
    &::before {
      content: '';
      display: block;
      padding-top: 48.066%;
    }
  }

  @include media-breakpoint-up('lg') {
    font-size: 22px;
    width: 1150px;
  }

  @include media-breakpoint-up('xxxl') {
    width: 1395px;
    font-size: 28px;
  }

  @keyframes overlay-appear {
    0% {
      opacity: 0;
      transform: translate(-50%, 12.5%) scale(0.9);
    }

    100% {
      opacity: 1;
      transform: translate(-50%, 0) scale(1);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: overlay-appear 0.25s ease-in 0s alternate 1 backwards;
    transition: opacity 0.25s ease-in, transform 0.25s ease-in;

    &.is-closing {
      opacity: 0;
      transform: translate(-50%, -12.5%) scale(0.9);
    }
  }

  .article {
    &-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1em;
      background-color: color('cream');

      @include media-breakpoint-up('md') {
        flex-direction: row;
      }

      /** Shadow */
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 15px;
        right: 25px;
        bottom: 0;
        left: 25px;
        z-index: -1;
        box-shadow: 0 0 75px -30px color('black');
      }
    }

    &-header {
      position: relative;
      min-height: 4em;
      margin-bottom: 1em;
      color: color('secondary');

      @include media-breakpoint-down('md') {
        padding-top: 4.5em;
      }

      .datetime {
        position: absolute;
        top: 0.25em;
        right: 0.25em;
        font-size: 1.5em;

        span {
          transform: none;
        }
      }
    }

    &-title,
    &-subtitle {
      font-family: $font-family-narrow;
      font-weight: $font-weight-medium;

      @include media-breakpoint-up('md') {
        padding-right: 7.25rem;
      }
    }

    &-title {
      margin-bottom: 0.25em;
      font-size: 1.6364em;
    }

    &-subtitle {
      font-size: 1.1818em;
    }

    &-content {
      color: color('secondary');
      font-size: 1.1818em;
    }

    &-images {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 0.5em;

      .image-stamp {
        max-width: calc(33.3333% - 0.35em);
        max-height: 8em;
        height: auto;
      }
    }
  }

  .overlay {
    &-side {
      order: 2;
      position: relative;

      @include media-breakpoint-up('md') {
        order: 1;
        width: 50%;
        padding: 1.5em;
      }

      & + .overlay-side {
        order: 1;

        @include media-breakpoint-up('md') {
          order: 2;
          padding: 0.25em 0.25em 0.25em 1.25em;

          &::before {
            content: '';
            position: absolute;
            top: 3em;
            bottom: 3em;
            left: 0;
            border-left: 1px solid color('tortilla');
          }
        }
      }
    }

    &-more-button {
      cursor: url('../../../images/cursors/postmark.svg') 62 26, pointer;
      margin: 1em 0;
      font-size: 1.6364em;

      @include media-breakpoint-up('md') {
        position: absolute;
        bottom: 3rem;
        right: 2rem;
        margin: 0;
      }

      .icon {
        margin-right: 0.5em;
        transform: translateY(0.2em);

        @media (prefers-reduced-motion: no-preference) {
          transition: transform 0.25s ease-in;
        }
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        .icon {
          transform: translate(0.5em, 0.2em);
        }
      }
    }

    &-close-button {
      width: 30%;
      height: 50%;

      .icon {
        font-size: 2em;
      }
    }
  }
}

/**
 * Overlay button with arrow icon
 */
.article.novelty-card,
.article.novelty-overlay {
  .novelty-card-overlay-button,
  .overlay-close-button {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 6;

    /** Shadow on the side */
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 50%;
      z-index: -1;
      box-shadow: 0 0 60px -45px color('cedar');
      clip-path: inset(0 -60px -60px 0);
      opacity: 0;

      @media (prefers-reduced-motion: no-preference) {
        transition: opacity 0.25s ease-in;
      }
    }

    .icon {
      position: absolute;
      right: 0.5em;
      bottom: 0;
      transform: rotate(35deg);
      transform-origin: 75% 100%;
      opacity: 0.5;

      @media (prefers-reduced-motion: no-preference) {
        transition: transform 0.25s ease-in;
        animation: color 20s infinite;
      }
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      &::after {
        opacity: 1;
      }

      .icon {
        transform: rotate(50deg);
      }
    }
  }
}
