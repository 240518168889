.form.search {
  width: 100%;

  .form {
    &-wrap {
      width: 100%;

      @include media-breakpoint-up('xs') {
        display: flex;
        align-items: flex-start;
      }
    }

    &-section {
      &:first-child {
        flex-grow: 1;
      }

      &:last-child {
        @include media-breakpoint-up('xs') {
          align-self: flex-end;
          width: calc(152px + 1em);
          padding-left: 1em;
        }

        @include media-breakpoint-up('sm') {
          width: calc(184px + 1em);
        }

        @include media-breakpoint-up('md') {
          width: calc(216px + 2em);
          padding-left: 2em;
        }
      }
    }

    &-row {
      @include media-breakpoint-up('md') {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        &:last-child .form-field {
          width: 100%;
          padding: 0;

          .form-field-error {
            right: 0;
          }
        }
      }

      @include media-breakpoint-up('xl') {
        flex-wrap: nowrap;

        &:first-child {
          margin-bottom: 1em;
        }
      }
    }

    &-field {
      position: relative;
      width: 100%;
      margin-bottom: 0.5em;

      @include media-breakpoint-up('md') {
        width: 50%;

        &:nth-child(odd) {
          padding-right: 1em;

          .form-field-error {
            right: 1rem;
          }
        }

        &:nth-child(even) {
          padding-left: 1em;
        }
      }

      @include media-breakpoint-up('xl') {
        margin-bottom: 0;

        &:nth-child(odd) {
          padding-right: 0;

          .form-field-error {
            right: 0;
          }
        }

        &:nth-child(even) {
          padding-left: 0;
        }

        &:not(:first-child) {
          padding-left: 0.5em;
        }

        &:not(:last-child) {
          padding-right: 0.5em;

          .form-field-error {
            right: 0.5rem;
          }
        }
      }

      @include media-breakpoint-up('xxl') {
        &:not(:first-child) {
          padding-left: 1em;
        }

        &:not(:last-child) {
          padding-right: 1em;

          .form-field-error {
            right: 1rem;
          }
        }
      }

      label {
        display: block;
        padding-bottom: 0.1em;
        border-bottom: 1px solid color('peacock');
        color: color('teal');
        font-family: $font-family-narrow;
        font-size: 0.6923em;
      }

      input,
      select,
      textarea {
        padding: 0;
        font-family: $font-family-headings;
        font-size: 0.8462em;
      }

      input[type='number'] {
        /* stylelint-disable-next-line property-no-vendor-prefix */
        -moz-appearance: textfield;
        appearance: textfield;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          /* stylelint-disable-next-line property-no-vendor-prefix */
          -webkit-appearance: none;
          appearance: none;
          margin: 0;
        }
      }

      &-error {
        position: absolute;
        z-index: 1;
        top: 0.3333em;
        right: 0;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        font-size: 0.5384em;
        color: color('ruby');
        transform: translateY(calc(100% - 0.1em));

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-out 0s 1 alternate backwards;
        }
      }
    }

    &-status,
    &-error {
      margin: 0;
      font-size: 0.8462em;
      font-weight: $font-weight-semibold;

      @media (prefers-reduced-motion: no-preference) {
        animation: appear 0.25s ease-out 0s 1 alternate backwards;
      }
    }

    &-status {
      color: color('teal');
    }

    &-error {
      color: color('ruby');
    }

    &-submit {
      position: relative;
      padding-top: 1em;
      text-align: right;

      .submit-button {
        width: 152px;
        height: 64px;
        font-size: 18px;

        @include media-breakpoint-up('sm') {
          width: 184px;
          height: 72px;
          font-size: 22px;
          line-height: $line-height-sm;
        }

        @include media-breakpoint-up('md') {
          width: 216px;
          height: 80px;
          font-size: 24px;
          line-height: $line-height-sm;
        }
      }

      .datetime {
        position: absolute;
        top: 30px;
        right: 148px;
        z-index: 2;
        font-size: 0.9231rem;

        @include media-breakpoint-up('sm') {
          top: 40px;
          right: 212px;
        }

        @media (prefers-reduced-motion: no-preference) {
          animation: postmark 0.3333s ease-in 0s 1 alternate backwards;
        }
      }

      .sent-message {
        top: 80px;
        right: -0.5em;
        position: absolute;
        z-index: 2;
        color: color('secondary');
        font-family: $font-family-narrow;
        font-size: 0.8462rem;
        font-weight: $font-weight-normal;
        white-space: nowrap;

        @include media-breakpoint-up('sm') {
          top: 88px;
        }

        @include media-breakpoint-up('md') {
          top: 96px;
        }

        @media (prefers-reduced-motion: no-preference) {
          animation: postmark 0.3333s ease-in 0.25s 1 alternate backwards;
        }

        span {
          display: inline-block;
          position: relative;
          width: 7.75em;
          text-align: center;
          transform: rotate(-2deg);

          &::before {
            @include icon('\e907', 7.75em, color('tortilla'));

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% - 0.075em));
            opacity: 0.7;
          }
        }
      }
    }

    &-reset {
      position: relative;
      z-index: 10;
      text-align: right;

      @media (prefers-reduced-motion: no-preference) {
        animation: appear 0.25s ease-out 1s 1 alternate backwards;
      }

      &-button {
        margin-top: 2.5em;

        @include media-breakpoint-up('md') {
          margin-left: -2em;
        }

        @include media-breakpoint-up('xxl') {
          margin-left: -3em;
        }

        .icon {
          margin-right: 0.5em;

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.25s ease-out;
          }
        }

        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          .icon {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
