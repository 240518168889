/**
 * Add a caligraphic underline to a title
 */
@mixin underline() {
  span {
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      bottom: 0.075em;
      left: 0;
      height: 3px;
      background: url('../../images/underline.svg') no-repeat center / 100% 5px;
      transform: scaleY(0.8);

      @include media-breakpoint-up('xs') {
        height: 4px;
        transform: none;
      }

      @include media-breakpoint-up('xl') {
        height: 5px;
      }
    }
  }
}
