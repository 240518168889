.form-field.checkbox {
  label {
    @include cursor('finger-press');

    display: block;
  }

  .form-field-icon {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 2rem;

    .icon {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      transition: color 0.25s ease-out, opacity 0.25s ease-out;
    }
  }

  .form-field-error {
    margin-left: 2rem;
    color: color('ruby');
    font-family: $font-family-base;
    font-size: 0.9167em;
    font-weight: $font-weight-semibold;
  }

  input {
    @include visually-hidden;

    &:focus,
    &:focus-visible,
    &:focus-within {
      & + .form-field-icon .icon {
        color: color('gold');
      }
    }

    & + .form-field-icon .icon-checkbox-checked {
      opacity: 0;
    }

    &:checked,
    &[aria-checked='true'] {
      & + .form-field-icon .icon-checkbox-checked {
        opacity: 1;
      }
    }
  }
}
