.datetime {
  display: block;
  color: color('cedar');
  font-family: $font-family-narrow;
  font-size: 1.0769em;
  font-weight: $font-weight-normal;

  &.l-postmark {
    opacity: 0.8;

    span {
      display: inline-block;
      position: relative;
      transform: rotate(-11deg);

      &::before {
        @include icon('\e905', 4.75em, color('tortilla'));

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% + 0.0333em));
        opacity: 0.7;
      }
    }
  }

  &.l-stamp {
    position: relative;
    z-index: 1;
    color: color('gold');

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: calc(6em / 330.25 * 594.25);
      height: 6em;
      background: url('../../images/stamp.svg') no-repeat center / cover;
      transform: translate(-19%, -88%) rotate(-45deg);

      @include media-breakpoint-up('xl') {
        transform: translate(-20%, -75%) rotate(-45deg);
      }
    }
  }
}
