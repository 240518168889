.template-frontpage {
  .section {
    &-footer {
      padding-bottom: var(--container-padding-v);
    }

    &.membersearches {
      .section-content.is-loading {
        /* stylelint-disable-next-line declaration-no-important */
        min-height: 11rem !important;
      }
    }
  }
}
