.table {
  width: 100%;
  border-collapse: collapse;

  .datetime {
    display: inline-block;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
  }

  thead {
    th {
      padding: 0.25rem;
      vertical-align: bottom;
      color: color('teal');
      font-family: $font-family-narrow;
      font-weight: $font-weight-normal;
      font-size: 0.6923em;

      &:not([class*='text-']) {
        text-align: left;
      }

      @include media-breakpoint-up('md') {
        padding: 0.5rem;
      }
    }

    @include media-breakpoint-down('sm') {
      @include visually-hidden;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: rgba(color('primary', false), 0.0175);
      }

      &.is-new {
        @media (prefers-reduced-motion: no-preference) {
          animation: highlight 10s ease-in-out 0s 1 alternate backwards;
        }
      }

      &.is-favourite {
        .button {
          .icon[class*='heart'] {
            color: color('ruby');
          }
        }
      }

      &.is-draft {
        td:not(.actions) {
          opacity: 0.4;
        }
      }
    }

    td {
      padding: 0.25rem;
      color: color('secondary');
      font-family: $font-family-headings;
      font-weight: $font-weight-normal;
      font-size: 0.8462em;

      @include media-breakpoint-up('md') {
        padding: 0.5rem;
      }

      @include media-breakpoint-down('sm') {
        @for $i from 1 through 12 {
          &:nth-child(#{$i})::before {
            content: var(--col-#{$i}-label) ' : ';
            opacity: 0.5;
          }
        }
      }

      &.actions {
        .button {
          position: relative;
          min-width: 1.5em;
          padding: 0.125em;
          font-size: 1em;

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            .icon {
              color: color('ruby');
            }
          }

          & + .button {
            margin-left: 0.25em;
          }

          &.info-button {
            &:hover,
            &.is-pressed {
              &::before {
                content: attr(aria-label);
                display: inline-block;
                position: absolute;
                top: 0;
                left: 50%;
                z-index: 10;
                padding: 0.25em;
                width: 10em;
                background-color: rgba(color('teal', false), 0.85);
                border-radius: 0.5em;
                box-shadow: 0 0 10px -5px color('teal');
                line-height: $line-height-sm;
                color: color('cream');
                font-size: 0.9167em;
                white-space: break-spaces;
                transform: translate(-50%, -100%);
              }

              &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0.5em;
                left: 50%;
                border-top: 0.5em solid rgba(color('teal', false), 0.85);
                border-right: 0.5em solid transparent;
                border-left: 0.5em solid transparent;
                font-size: 0.9167em;
                transform: translate(-50%, -100%);
              }
            }
          }
        }

        .icon {
          font-size: 0.9em;

          &.icon-times {
            color: color('ruby');
            font-size: 0.8em;
          }

          &.icon-circle-info {
            color: color('gold');
          }
        }
      }
    }
  }

  @include media-breakpoint-down('sm') {
    tbody {
      display: block;

      tr {
        display: flex;
        flex-direction: column;
        padding: 1em 0;
      }

      td {
        display: block;

        &:nth-child(n + 1) {
          width: 100%;
        }

        &.text-center,
        &.text-right {
          text-align: left;
        }
      }
    }
  }

  @include media-breakpoint-up('sm') {
    th:first-child,
    td:first-child {
      padding-left: 1.3333rem;
    }

    th:last-child,
    td:last-child {
      padding-right: 1.3333rem;
    }

    &.l-full-width {
      th:first-child,
      td:first-child {
        padding-left: var(--container-padding-h);
      }

      th:last-child,
      td:last-child {
        padding-right: var(--container-padding-h);
      }
    }
  }

  /** Color variants */
  &.c-ruby {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(color('ruby', false), 0.02);
      }
    }
  }

  &.c-teal {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(color('teal', false), 0.02);
      }
    }
  }

  &.c-peacock {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(color('peacock', false), 0.02);
      }
    }
  }

  &.c-gold {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(color('gold', false), 0.02);
      }
    }
  }

  &.c-cedar {
    tbody {
      tr:nth-child(odd) {
        background-color: rgba(color('cedar', false), 0.02);
      }
    }
  }
}
