.template-club-members {
  .section.flexible + .section.members {
    padding-top: 0;

    &::before {
      @include hatched-background;

      content: '';
      margin-bottom: var(--container-padding-v);
    }

    .loader {
      &::after {
        @include hatched-background(color('sand'));

        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 11;
      }
    }
  }
}
