.template-single-event {
  .template {
    &-content {
      min-height: calc(100vh - var(--template-header-height) - var(--footer-nav-height) - var(--footer-height) + 16px);
    }

    &-footer {
      // padding: 0;

      &-nav.nav {
        //
      }
    }
  }
}
