/**
 * Wrap a content within a `min-$query` media query
 *
 * @param string $breakpoint  The breakpoint name
 * @param string $query 'width'|'height'  The query name
 */
@mixin media-breakpoint-up($breakpoint, $query: 'width') {
  @if map-has-key($breakpoints, '#{$query}-#{$breakpoint}') {
    @media (min-#{$query}: map-get($breakpoints, '#{$query}-#{$breakpoint}')) {
      @content;
    }
  } @else {
    @warn 'No value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

/**
 * Wrap a content within a `max-$query` media query
 *
 * @param string $breakpoint  The breakpoint name
 * @param string $query 'width'|'height'  The query name
 */
@mixin media-breakpoint-down($breakpoint, $query: 'width') {
  @if map-has-key($breakpoints, '#{$query}-#{$breakpoint}') {
    @media (max-#{$query}: map-get($breakpoints, '#{$query}-#{$breakpoint}') - 1) {
      @content;
    }
  } @else {
    @warn 'No value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

/**
 * Wrap a content within a `min-$query and max-$query` media query
 *
 * @param string $breakpoint  The breakpoint name
 * @param string $query 'width'|'height'  The query name
 */
@mixin media-breakpoint-between($breakpointMin, $breakpointMax, $query: 'width') {
  @if map-has-key($breakpoints, '#{$query}-#{$breakpointMin}') and
    map-has-key($breakpoints, '#{$query}-#{$breakpointMax}')
  {
    @media (min-#{$query}: map-get($breakpoints, '#{$query}-#{$breakpointMin}')) and (max-#{$query}: map-get($breakpoints, '#{$query}-#{$breakpointMax}') - 1) {
      @content;
    }
  } @else {
    @warn 'No value could be retrieved from `#{$breakpointMin}` or `#{$breakpointMax}`. Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}
