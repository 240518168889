html {
  box-sizing: border-box;

  @include cursor('finger-up');
  @include scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  --current-color: var(--primary);

  background-color: $body-bg;
  color: $body-color;
}

img,
video {
  max-width: 100%;
  height: auto;
}

form {
  input,
  select,
  textarea {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: color('secondary');
    font-family: $font-family-narrow;
    font-weight: $font-weight-normal;

    &:placeholder-shown::placeholder {
      color: inherit;
      opacity: 0.4;
    }

    &:disabled {
      @include cursor('finger-up');
    }
  }

  input,
  textarea {
    @include cursor('text');
  }
}

[hidden] {
  display: none;
}

.grecaptcha-badge {
  /* stylelint-disable-next-line declaration-no-important -- Override inline styles */
  display: none !important;
}
