.section.intro {
  padding-top: calc(var(--container-padding-v) / 2);

  @include media-breakpoint-up('xxxl') {
    padding-left: calc(var(--container-padding-h) * 0.75);
    padding-right: calc(var(--container-padding-h) * 0.75);
  }

  .section {
    &-title {
      margin-bottom: 2.5em;
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      padding: 0;

      @include media-breakpoint-up('xs') {
        flex-wrap: nowrap;
      }
    }
  }

  .intro {
    &-text {
      flex-grow: 1;

      @include media-breakpoint-up('xs') {
        padding-right: calc(var(--container-padding-h) * 4);
      }

      @include media-breakpoint-up('sm') {
        padding-right: calc(var(--container-padding-h) * 2);
      }

      @include media-breakpoint-up('lg') {
        padding-right: var(--container-padding-h);
      }

      .button {
        width: 184px;
        margin-top: 2em;

        @include media-breakpoint-up('sm') {
          width: 208px;
        }

        @include media-breakpoint-up('lg') {
          width: 248px;
        }

        @include media-breakpoint-up('xxl') {
          width: 264px;
        }
      }
    }

    &-animation {
      align-self: flex-end;
      position: sticky;
      z-index: 2;
      bottom: 2.75%;
      width: 50%;
      min-width: 50%;
      max-width: 50%;
      margin-left: 45%;
      transform: translateY(calc(var(--container-padding-v) + 25%));

      @include media-breakpoint-up('xs') {
        bottom: 1.75%;
        width: 35%;
        min-width: 35%;
        max-width: 35%;
        margin-left: 0;
        transform: translate(calc(var(--container-padding-h) / -2), calc(var(--container-padding-v) + 10.5%));
      }

      @include media-breakpoint-up('sm') {
        bottom: 3.25%;
        transform: translate(0, calc(var(--container-padding-v) + 10.5%));
      }

      @include media-breakpoint-up('lg') {
        bottom: 5%;
        transform: translate(calc(var(--container-padding-h) / 2), calc(var(--container-padding-v) + 10.5%));
      }

      @include media-breakpoint-up('xl') {
        width: 40%;
        min-width: 40%;
        max-width: 40%;
        transform: translate(calc(var(--container-padding-h) / 2), calc(var(--container-padding-v) + 17.5%));
      }
    }
  }
}
