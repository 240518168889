.section.mysearches {
  padding-top: calc(var(--container-padding-v) / 2);

  .section {
    &-title {
      &::before {
        @include icon('\2605', 1em, color('gold'));

        margin-right: 1em;
      }
    }

    &-content {
      position: relative;
      overflow: hidden;
      padding: 0.75em 0 0 0;
      background-color: rgba(color('white', false), 0.5);
      box-shadow: 0 0 60px -60px color('cedar');

      p.no-result {
        margin: 0;
        padding: 1.25em 2em 2em 2em;
      }
    }

    &-footer {
      .nav.footer-nav {
        height: auto;
        padding: 0;
        background-color: transparent;

        &::before {
          // display: none;
          opacity: 0.75;
        }

        .nav-section {
          padding: 0.75em 0.5em;

          &.l-prev .button-label {
            padding-left: 3em;
          }

          &.l-next .button-label {
            padding-right: 3em;
          }
        }

        .button {
          width: auto;
          min-width: auto;
          height: auto;
          margin: 0;
          font-size: 1.25em;
          line-height: $line-height-base;

          &-label {
            font-size: 0.6923em;
          }

          .icon {
            transform: translateY(0.05em);
          }
        }
      }
    }
  }

  &.l-favourites {
    .section {
      &-title {
        &::before {
          content: '\2764';
          font-size: 0.9em;
        }
      }
    }
  }
}
