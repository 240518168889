.section.membersearches {
  padding: 0;

  .section {
    &-header {
      //
    }

    &-content {
      //
    }

    &-footer {
      text-align: center;

      > :not(.footer-nav) .button {
        margin: 0 0.5em;
      }
    }
  }
}
