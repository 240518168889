.template {
  min-height: calc(100vh - var(--footer-height) + 16px);

  &-header {
    position: relative;
    z-index: 11;
    text-align: center;
    text-shadow: 1px 1px rgba(color('cedar', false), 0.5);
    overflow-x: hidden;

    > .background {
      display: flex;
      align-items: center;
      height: var(--template-header-height);
      /* stylelint-disable-next-line declaration-no-important -- Override color variants from .background.l-stamp-outline */
      padding-top: var(--header-height) !important;
    }

    .container {
      @include media-breakpoint-up('xxxl') {
        padding-left: calc(var(--container-padding-h) * 1.75);
        padding-right: calc(var(--container-padding-h) * 1.75);
      }
    }

    .template {
      &-title {
        margin-bottom: 0;

        span::after {
          filter: brightness(100) drop-shadow(1px 1px rgba(color('cedar', false), 0.5));
        }
      }
    }

    &-text {
      margin-top: 1em;
      text-align: center;

      &.l-arrow {
        @include media-breakpoint-up('md') {
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            @include icon('\27a6', 4em, color('white'));

            display: inline-block;
            margin-left: 0.5em;
            opacity: 0.5;
            text-align: right;
            transform: rotate(180deg);
          }
        }
      }
    }

    &.l-cols {
      > .background {
        @include media-breakpoint-down('md') {
          height: 304px;
        }
      }

      @include media-breakpoint-up('md') {
        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .template {
          &-title {
            width: 50%;
            padding-right: calc(var(--container-padding-h) / 2);
            text-align: left;
          }

          &-header-text {
            width: 50%;
            padding-left: calc(var(--container-padding-h) / 2);
            margin-top: 0;
            text-align: right;
          }
        }
      }
    }

    & + .template-content {
      margin-top: -8px;
      padding-top: 8px;
    }
  }

  &-title {
    @include underline;
  }

  &-subtitle {
    font-family: $font-family-narrow;
    font-weight: $font-weight-medium;
  }

  &-content {
    position: relative;
  }

  &-footer {
    clear: both;
  }

  &.is-loading {
    overflow: hidden;

    .template-content {
      min-height: 18em;
    }
  }
}
