[class^='icon-'],
[class*=' icon-'] {
  font-family: 'icomoon' !important; // stylelint-disable-line
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon($char, $size: 18px, $color: #000) {
  content: $char;
  color: $color;
  font-family: 'icomoon' !important; // stylelint-disable-line
  font-size: $size;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/**
 * Don't forget to add the icon to the <Icon> component PropTypes when you add an icon
 */

.icon-facebook::before,
.icon-f::before {
  content: '\e900';
}

.icon-instagram::before,
.icon-i::before {
  content: '\e901';
}

.icon-twitter::before,
.icon-t::before {
  content: '\e902';
}

.icon-youtube::before,
.icon-y::before {
  content: '\e903';
}

.icon-stamp::before {
  content: '\e906';
}

.icon-stamp-plain::before {
  content: '\e904';
}

.icon-postmark::before {
  content: '\e905';
}

.icon-postmark-rect::before {
  content: '\e907';
}

.icon-lock::before {
  content: '\1f512';
}

.icon-lock-open::before {
  content: '\1f513';
}

.icon-bars::before {
  content: '\2550';
}

.icon-times::before {
  content: '\2573';
}

.icon-finger-left::before {
  content: '\261c';
}

.icon-finger-right::before {
  content: '\261e';
}

.icon-arrow-right-curved::before {
  content: '\27a6';
}

.icon-arrow-right-circular::before {
  content: '\27f3';
}

.icon-arrow-left-dotted::before {
  content: '\21e0';
}

.icon-arrow-right-dotted::before {
  content: '\21e2';
}

.icon-envelope::before {
  content: '\2709';
}

.icon-link::before {
  content: '\1f517';
}

.icon-phone::before {
  content: '\1f4de';
}

.icon-megaphone::before {
  content: '\1f4e3';
}

.icon-page::before {
  content: '\1f5cf';
}

.icon-pencil::before {
  content: '\1f589';
}

.icon-play::before {
  content: '\25b6';
}

.icon-star-plain::before {
  content: '\2605';
}

.icon-magnifier::before {
  content: '\1f50d';
}

.icon-heart::before {
  content: '\2661';
}

.icon-heart-plain::before {
  content: '\2764';
}

.icon-circle::before {
  content: '\25ef';
}

.icon-circle-plus::before {
  content: '\2295';
}

.icon-circle-minus::before {
  content: '\2296';
}

.icon-circle-1::before {
  content: '\2460';
}

.icon-circle-2::before {
  content: '\2461';
}

.icon-circle-3::before {
  content: '\2462';
}

.icon-circle-1-plain::before {
  content: '\2776';
}

.icon-circle-2-plain::before {
  content: '\2777';
}

.icon-circle-3-plain::before {
  content: '\2778';
}

.icon-circle-info::before {
  content: '\1f6c8';
}

.icon-checkbox::before {
  content: '\2610';
}

.icon-checkbox-checked::before {
  content: '\2611';
}

.icon-list-star::before {
  content: '\204e';
}

.icon-list-dot::before {
  content: '\2022';
}

.icon-list-cross::before {
  content: '\2a2f';
}
