/**
 * Whether to use css or sass variables
 * Responsive variables only works with $css-vars: true
 */
$css-vars: true !default;

 /**
  * Colors
  */
$colors: (
  --primary:      #453e40,
  --secondary:    #0a3742,
  --ruby:         #8f1d44,
  --teal:         #548591,
  --peacock:      #0a3742,
  --gold:         #918b49,
  --cedar:        #453e40,
  --tortilla:     #848073,
  --sand:         #f5efdf,
  --cream:        #fffef8,
  --black:        #000,
  --white:        #fff,
);
@if $css-vars {
  :root {
    @each $color, $value in $colors {
      #{$color}: #{$value};
    }
  }
}

/**
 * Breakpoints
 */
$breakpoints: (
  width-xxs:    460px,
  width-xs:     576px,
  width-sm:     768px,
  width-md:     992px,
  width-lg:    1200px,
  width-xl:    1560px,
  width-xxl:   1900px,
  width-xxxl:  2260px,
  width-huge:  2520px,
  height-xxs:   320px,
  height-xs:    420px,
  height-sm:    540px,
  height-md:    660px,
  height-lg:    800px,
  height-xl:    940px,
  height-xxl:  1080px,
  height-xxxl: 1220px,
  height-huge: 1360px
);

/**
 * Fonts
 */
$font-family-base: elido, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-headings: swing-king, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-family-narrow: kopius-condensed, 'Helvetica Neue', Calibri, Helvetica, Arial, sans-serif;
$font-size-base: 1.5rem; // Assumes the browser default, typically `16px`
$font-size-xs:  ($font-size-base / 1.5);
$font-size-sm:  ($font-size-base / 1.25);
$font-size-lg:  ($font-size-base * 1.25);
$font-size-xl:  ($font-size-base * 1.5);
$font-weight-thin:      100; // Available for
$font-weight-xlight:    200; // Available for
$font-weight-light:     300; // Available for
$font-weight-normal:    400; // Available for elido, kopius-condensed, swing-king
$font-weight-medium:    500; // Available for kopius-condensed
$font-weight-semibold:  600; // Available for elido
$font-weight-bold:      700; // Available for
$font-weight-extrabold: 800; // Available for
$font-weight-black:     900; // Available for
$font-weight-ultra:    1000; // Available for
$line-height-base: 1.35;
$line-height-sm:   1.15;
$line-height-lg:   1.5;

/**
 * Headings
 */
$h1-font-size: 2em;
$h2-font-size: 1.8em;
$h3-font-size: 1.4em;
$h4-font-size: 1.2em;
$h5-font-size: 1.1em;
$h6-font-size: 1em;

/**
 * Links
 */
$link-color:            color('secondary');
$link-decoration:       none;
$link-hover-color:      color('primary');
$link-hover-decoration: none;

/**
 * Body
 */
$body-bg:    color('cream');
$body-color: color('primary');

/**
 * Custom variables
 */
$variables: (
  --container-padding-h: 2vw,
  --container-padding-v: 2em,
  --container-max-width: 2880px,
  --font-size-base: 1.125em,
  --header-height: 56px,
  --header-height-with-logo: 160px,
  --footer-height: 112px,
  --template-header-height: 320px,
  --footer-nav-height: 240px,
);
@if $css-vars {
  :root {
    @each $variable, $value in $variables {
      #{$variable}: #{$value};
    }
  }
}

/**
 * Responsive variables
 */
@if $css-vars {
  @include media-breakpoint-up('sm') {
    $variables: (
      --container-padding-h: 4vw,
      --container-padding-v: 3em,
      --font-size-base: 1.25em,
      --footer-height: 96px,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up('lg') {
    $variables: (
      --container-padding-h: 6vw,
      --container-padding-v: 4em,
      --font-size-base: 1.3333em,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up('xl') {
    $variables: (
      --font-size-base: 1.4166em,
      --footer-height: 80px,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up('xxl') {
    $variables: (
      --container-padding-h: 7vw,
      --container-padding-v: 4.5em,
      --font-size-base: 1.5em,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }

  @include media-breakpoint-up('xxxl') {
    $variables: (
      // --container-padding-h: 10vw,
      --container-padding-v: 6em,
    );
    :root {
      @each $variable, $value in $variables {
        #{$variable}: #{$value};
      }
    }
  }
}
