.section.flexible {
  padding: 0;

  hr {
    margin: 0;
    border: none;
    clear: both;

    @include hatched-background;
  }

  .flexible-content {
    padding: calc(var(--container-padding-v) / 2) 0;
    clear: both;

    &:first-child {
      margin-top: -8px;
      padding-top: calc(var(--container-padding-v) + 8px);
    }

    &:last-child {
      padding-bottom: var(--container-padding-v);
    }

    .container {
      @include media-breakpoint-up('xxxl') {
        padding-left: calc(var(--container-padding-h) * 1.75);
        padding-right: calc(var(--container-padding-h) * 1.75);
      }
    }

    .flexible {
      &-title {
        font-family: $font-family-headings;
        font-weight: $font-weight-normal;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &-text {
        h4,
        h5,
        h6,
        .h4,
        .h5,
        .h6 {
          color: color('secondary');

          a {
            font-family: $font-family-narrow;
            font-weight: $font-weight-medium;
          }
        }

        ul {
          li {
            list-style: none;

            &::before {
              @include icon('\2a2f', 0.75em, color('gold'));

              display: inline-block;
              position: absolute;
              transform: translate(-140%, 50%);
            }

            ul li {
              &::before {
                content: '\2022';
                font-size: 0.6em;
                transform: translate(-160%, 75%);
              }

              ul li {
                &::before {
                  content: '\204e';
                  font-size: 0.85em;
                  transform: translate(-135%, 45%);
                }
              }
            }
          }
        }

        a {
          /** Embedded in ../_section.scss to avoid a build error */
          // @include cursor('finger-right');

          font-family: $font-family-headings;
          font-weight: $font-weight-normal;
          line-height: 1em;

          &::before {
            @include icon('\1f517', 0.75em, color('secondary'));

            display: inline-block;
            margin: 0 0.5em 0 0.25em;
            transform: translateX(0);

            @media (prefers-reduced-motion: no-preference) {
              transition: color 0.25s ease-out, transform 0.25s ease-out;
            }
          }

          &[href^='mailto:']::before {
            content: '\2709';
          }

          &[href^='tel:']::before {
            content: '\1f4de';
          }

          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            opacity: 0.9;

            &::before {
              color: color('gold');
              transform: translateX(0.25em);
            }
          }
        }
      }

      &-row {
        display: flex;
        flex-direction: column;
        gap: 0.75em;

        .flexible-col {
          @include media-breakpoint-down('xs') {
            &:empty {
              display: none;
            }
          }
        }

        &:not([data-col-count]),
        &[data-col-count='2'] {
          @include media-breakpoint-up('xs') {
            flex-direction: row;
            gap: var(--container-padding-h);

            .flexible-col {
              width: 50%;
              margin-bottom: 0;
            }
          }
        }

        &[data-col-count='3'] {
          @include media-breakpoint-up('md') {
            flex-direction: row;
            gap: var(--container-padding-h);

            .flexible-col {
              width: 33.3333%;
              margin-bottom: 0;
            }
          }
        }
      }

      &-images {
        display: flex;
        margin: calc(0px - var(--container-padding-h) / 4);

        .image {
          width: 100%;
          padding: calc(var(--container-padding-h) / 4);

          img {
            display: block;
            width: 100%;
          }
        }

        &[data-image-count='2'] {
          @include media-breakpoint-between('xs', 'sm') {
            flex-wrap: wrap;

            .image {
              width: 100%;
            }
          }
        }

        &[data-image-count='4'] {
          flex-wrap: wrap;

          .image {
            width: 50%;
          }
        }
      }
    }

    /** ACF "Text" layout */
    &.text {
      //
    }

    /** ACF "Text & images" layout */
    &.textimages {
      .flexible-row {
        &[data-col-count='3'] {
          @include media-breakpoint-up('md') {
            .flexible-col {
              &:first-child {
                width: calc(33.3333% - var(--container-padding-h) * 2 / 3);
              }

              &:last-child {
                width: calc(66.6666% - var(--container-padding-h) * 1 / 3);
              }
            }
          }
        }
      }

      &.l-text-right {
        .flexible-col {
          &:first-child {
            order: 2;
          }

          &:last-child {
            order: 1;
          }
        }
      }
    }

    /** ACF "Columns" layout */
    &.cols {
      //
    }

    /** ACF "Gallery" layout */
    &.gallery {
      .flexible {
        &-images {
          flex-wrap: wrap;

          @include media-breakpoint-up('md') {
            margin: calc(0px - var(--container-padding-h) / 8);
          }

          .image {
            width: 50%;

            @include media-breakpoint-up('xs') {
              width: 33.3333%;
            }

            @include media-breakpoint-up('md') {
              width: 25%;
              padding: calc(var(--container-padding-h) / 8);
            }

            @include media-breakpoint-up('lg') {
              width: 20%;
            }

            @include media-breakpoint-up('xxxl') {
              width: 16.6666%;
            }
          }
        }
      }
    }

    /** ACF "Stamps Gallery" layout */
    &.stamps {
      &:first-child::after {
        top: calc(0px - var(--container-padding-v) / 2);
      }

      &:last-child::after {
        bottom: calc(0px - var(--container-padding-v) / 2);
      }

      .stamps-images {
        display: flex;
        flex-wrap: wrap;
        gap: calc(var(--container-padding-h) / 2);

        @include media-breakpoint-up('md') {
          gap: calc(var(--container-padding-h) / 4);
        }

        .image {
          img {
            display: block;
            max-width: 9.25em;
            max-height: 9.25em;
          }
        }
      }
    }

    /** ACF "Accordion" layout */
    &.accordion {
      .flexible {
        &-title {
          margin-bottom: 0.5em;
        }

        &-accordion {
          &:not(:last-child) {
            margin-bottom: 0.25em;
          }

          .collapsable {
            &-button {
              display: flex;
              align-items: flex-start;
              text-align: left;

              &::before {
                @include icon('\27a6', 0.9em, color('gold'));

                display: inline-block;
                margin-right: 0.5em;
                transform: translateY(0.65em);

                @media (prefers-reduced-motion: no-preference) {
                  transition: transform 0.25s ease-out;
                }
              }

              .flexible-title {
                margin: 0.25em 0;
              }
            }

            &-content {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;

              @include media-breakpoint-up('sm') {
                flex-wrap: nowrap;
              }

              .image {
                padding: 0.5em 0;
                margin: 0 auto;

                @include media-breakpoint-up('sm') {
                  margin-right: calc(var(--container-padding-h) / 2);
                }

                img {
                  display: block;
                  width: auto;
                  min-width: 10em;
                  max-height: 9em;

                  @include media-breakpoint-up('sm') {
                    max-height: none;
                  }

                  @include media-breakpoint-up('md') {
                    max-width: 15em;
                  }
                }
              }

              .flexible-text {
                flex-grow: 1;
              }
            }
          }

          &:not(.is-collapsed) {
            .collapsable-button::before {
              transform: translateY(0.65em) rotate(45deg);
            }
          }
        }
      }
    }

    /** ACF "Header" layout */
    &.header {
      padding: 0;
      overflow-x: hidden;

      > .background {
        display: flex;
        align-items: center;
        height: 240px;
      }

      .flexible-title {
        text-align: center;
      }

      &.l-cols {
        text-align: center;

        > .background {
          height: 304px;

          @include media-breakpoint-up('lg') {
            height: 288px;
          }

          @include media-breakpoint-up('xl') {
            height: 272px;
          }
        }

        .container {
          @include media-breakpoint-up('md') {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        .flexible {
          &-title {
            @include media-breakpoint-up('md') {
              flex-grow: 1;
              min-width: 9em;
              padding-right: var(--container-padding-h);
              margin-bottom: 0;
              text-align: left;
            }

            @include media-breakpoint-up('xl') {
              min-width: 11em;
            }

            @include media-breakpoint-up('xxxl') {
              min-width: 15em;
              padding-right: calc(var(--container-padding-h) / 2);
            }
          }

          &-subtitle {
            margin-bottom: 0.5em;
            font-family: $font-family-narrow;

            strong {
              font-weight: $font-weight-medium;
            }
          }
        }

        .header {
          &-text {
            @include media-breakpoint-up('md') {
              margin-top: 0;
              text-align: right;
            }
          }

          &-link {
            display: inline-block;
            margin: 1em auto 0 auto;

            @include media-breakpoint-up('md') {
              padding-left: var(--container-padding-h);
            }

            @include media-breakpoint-up('xxxl') {
              padding-left: calc(var(--container-padding-h) / 2);
            }

            .link {
              display: flex;
              align-items: center;
              color: color('white');
              font-family: $font-family-headings;
              font-size: 1.3em;
              font-weight: $font-weight-normal;

              @media (prefers-reduced-motion: no-preference) {
                transition: opacity 0.25s ease-out;
              }

              &::before {
                @include icon('\261e', 1.5em, color('white'));

                display: inline-block;
                margin-right: 1rem;

                @media (prefers-reduced-motion: no-preference) {
                  transition: transform 0.25s ease-out;
                }
              }

              &:hover,
              &:focus,
              &:focus-visible,
              &:focus-within {
                opacity: 0.9;

                &::before {
                  transform: translateX(0.25em);
                }
              }
            }
          }
        }
      }

      & + .flexible-content {
        padding-top: calc(var(--container-padding-v) / 2 + 4px);
        margin-top: -4px;
      }
    }

    /** Layout variants */
    &.c-white {
      position: relative;
      background-color: rgba(color('white', false), 0.8);

      &:first-child::before,
      &:last-child::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: calc(var(--container-padding-h) / 2);
        background-color: rgba(color('white', false), 0.8);
      }

      &:first-child::before {
        top: 0;
        transform: translateY(-100%);
      }

      &:last-child::after {
        bottom: 0;
        transform: translateY(100%);
      }
    }

    &.c-ruby {
      @include pale-gradient-background('ruby');
    }

    &.c-teal {
      @include pale-gradient-background('teal');
    }

    &.c-peacock {
      @include pale-gradient-background('peacock');
    }

    &.c-gold {
      @include pale-gradient-background('gold');
    }

    &.c-cedar {
      @include pale-gradient-background('cedar');
    }

    &.c-paper {
      background: url('../../../images/bg-paper.jpg') repeat fixed;
    }

    &.l-pb-0 {
      padding-bottom: 0;
    }
  }
}
