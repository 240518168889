/**
 * Add a stamp background to an element.
 *
 ** The element width & height should be a multiple of 8px
 *
 * @param string $background-color  The background color
 * @param string|false $shadow-color  The shadow color
 */
@mixin stamp-background($background-color, $shadow-color: false) {
  position: relative;
  z-index: 1;
  padding: 8px;

  @if $shadow-color {
    background-image: radial-gradient(transparent 0, transparent 2px, #{$shadow-color} 2px);
    background-size: 8px 8px;
    background-position: -4px -4px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    z-index: -1;
    background-color: $background-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    background-image: radial-gradient(transparent 0, transparent 2px, #{$background-color} 2px);
    background-size: 8px 8px;
    background-position: -4px -4px;

    @if $shadow-color {
      transform: translate(-1px, -1px);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    &::before,
    &::after {
      transition: background-color 0.35s ease-out, background-image 0.35s ease-out;
    }
  }
}

/**
 * Update the color of the stamp background (added with `@mixin stamp-background`) of an element.
 *
 * @param string $background-color  The background color
 */
@mixin stamp-background-hover($background-color) {
  &::before {
    background-color: $background-color;
  }

  &::after {
    background-image: radial-gradient(transparent 0, transparent 2px, #{$background-color} 2px);
  }
}

/**
 * Add a large stamp background to an element.
 *
 ** The element width & height should be a multiple of 16px
 *
 * @param string $background-color  The background color
 * @param string|bool $shadow-color  The shadow color
 * @param bool $full-screen-width  Whether the element is displayed in full screen width
 */
@mixin stamp-background-large($background-color, $shadow-color: false, $full-screen-width: false) {
  position: relative;
  z-index: 1;
  padding: 8px;

  @if $full-screen-width {
    margin-left: -8px;
    margin-right: -8px;
  }

  @if $shadow-color {
    background-image: radial-gradient(transparent 0, transparent 4px, #{$shadow-color} 4px);
    background-size: 16px 16px;
    background-position: -8px -8px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    z-index: -1;
    background-color: $background-color;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -2;
    background-image: radial-gradient(transparent 0, transparent 4px, #{$background-color} 4px);
    background-size: 16px 16px;
    background-position: -8px -8px;

    @if $shadow-color {
      transform: translate(-1px, -1px);
    }
  }
}

/**
 * Add a radial gradient background to an element.
 *
 * @param string $background-color  The background color name from the `$colors` array of _variables.scss
 */
@mixin pale-gradient-background($background-color) {
  @if $background-color != 'cream' {
    background: radial-gradient(
      ellipse,
      rgba(color($background-color, false), 0.1) 0%,
      rgba(lighten(color($background-color, false), 10%), 0.1) 40%,
      rgba(darken(color($background-color, false), 5%), 0.2) 140%
    );
  } @else {
    background: radial-gradient(
      ellipse,
      rgba(lighten(color($background-color, false), 5%), 1) 0%,
      rgba(color($background-color, false), 0.2) 40%,
      rgba(darken(color($background-color, false), 25%), 0.3) 140%
    );
  }
}

/**
 * Transform an element to a hatched "air mail envelope border" like separator
 */
@mixin hatched-background($background-color: transparent) {
  display: block;
  width: 100%;
  height: 10px;
  background-image: linear-gradient(
    115deg,
    color('ruby') 0,
    color('ruby') 28px,
    #{$background-color} 28px,
    #{$background-color} 40px,
    color('peacock') 40px,
    color('peacock') 68px,
    #{$background-color} 68px,
    #{$background-color} 80px,
    color('ruby') 80px,
    color('ruby') 108px,
    #{$background-color} 108px,
    #{$background-color} 120px
  );
  background-size: 88px 100%;
}
