@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes highlight {
  0% {
    background-color: rgba(color('gold', false), 0);
  }

  5% {
    background-color: rgba(color('gold', false), 0.45);
  }

  10% {
    background-color: rgba(color('gold', false), 0.4);
  }

  100% {
    background-color: rgba(color('primary', false), 0.0175);
  }
}

@keyframes postmark {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes color {
  0% {
    color: color('cedar');
  }

  20% {
    color: color('ruby');
  }

  40% {
    color: color('teal');
  }

  60% {
    color: color('peacock');
  }

  80% {
    color: color('gold');
  }

  100% {
    color: color('cedar');
  }
}
