.section.members {
  position: relative;
  overflow: hidden;

  .loader {
    animation: none;
  }

  .members {
    &-filters {
      margin-bottom: 2em;
      color: color('secondary');
      font-family: $font-family-narrow;
      font-weight: $font-weight-medium;
    }
  }

  .article.members-article {
    .article {
      &-header {
        display: flex;
        align-items: center;

        .collapsable-button {
          flex-grow: 1;
          text-align: left;
        }
      }

      &-title {
        padding-left: 2rem;
        margin: 0.25em 0;
        color: color('secondary');

        .p-role {
          color: color('teal');
        }
      }

      &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        position: relative;
        z-index: 1;
        padding-bottom: var(--container-padding-v);

        @include media-breakpoint-up('sm') {
          flex-wrap: nowrap;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: calc(var(--container-padding-v) / 2);
          right: 0;
          left: 0;
          z-index: -1;

          @include hatched-background;
        }

        .image {
          padding: 1em 0;
          margin: 0 auto;

          @include media-breakpoint-up('sm') {
            margin-right: calc(var(--container-padding-h) / 2);
          }

          img {
            display: block;
            width: auto;
            min-width: 10em;
            max-height: 9em;

            @include media-breakpoint-up('sm') {
              max-height: none;
            }

            @include media-breakpoint-up('md') {
              max-width: 15em;
            }
          }
        }
      }

      &-bio {
        flex-grow: 1;
        padding: 1em 0;
      }

      &-contacts {
        min-width: 12.5em;
        padding: 1em 0;

        @include media-breakpoint-up('sm') {
          padding-left: calc(var(--container-padding-h) / 2);
        }

        @include media-breakpoint-up('lg') {
          min-width: 15em;
        }

        .h-adr {
          font-weight: $font-weight-semibold;
        }
      }

      &-contacts.contacts {
        .contacts-title {
          color: color('secondary');
        }
      }
    }

    .collapsable {
      &-button {
        .article-title {
          display: flex;
          padding-left: 0;

          &::before {
            @include icon('\27a6', 0.9em, color('gold'));

            display: inline-block;
            margin-right: 0.5em;
            transform: translateY(0.15em);

            @media (prefers-reduced-motion: no-preference) {
              transition: transform 0.25s ease-out;
            }
          }
        }
      }

      &:not(.is-collapsed) {
        .collapsable-button .article-title::before {
          transform: translateY(0.15em) rotate(45deg);
        }
      }
    }
  }
}
