.section.events {
  padding: 0;

  .section {
    &-content {
      padding: var(--container-padding-v) 0;
    }
  }

  .events {
    &-enum.enum {
      @include media-breakpoint-up('lg') {
        display: grid;
        grid-template-columns: 50% 50%;
      }

      .enum-item {
        &:not(:last-child) {
          margin-bottom: calc(var(--container-padding-v) / 2 * 1.5);
        }

        &:first-child {
          /* stylelint-disable-next-line declaration-no-important -- Override &:nth-child(3n - 2) */
          transform: translateX(10vw) rotate(2deg) !important;

          &:not(:last-child) .event {
            margin-top: var(--container-padding-v);

            &.has-image {
              margin-top: 37.5%;
            }
          }

          @include media-breakpoint-up('md') {
            /* stylelint-disable-next-line declaration-no-important -- Override &:nth-child(3n - 2) */
            transform: translateX(15vw) rotate(2deg) !important;

            &:not(:last-child) .event {
              &.has-image {
                margin-top: 27.5%;
              }
            }
          }

          @include media-breakpoint-up('lg') {
            /* stylelint-disable-next-line declaration-no-important -- Override &:nth-child(3n - 2) */
            transform: rotate(2deg) !important;

            &:not(:last-child) .event {
              margin-top: 35%;

              &.has-image {
                margin-top: 40%;
              }
            }
          }
        }

        &:first-child:last-child {
          @include media-breakpoint-up('lg') {
            /* stylelint-disable-next-line declaration-no-important -- Override many rules :-) */
            transform: translateX(50%) rotate(2deg) !important;
          }
        }

        /** 1, 4, 7 */
        &:nth-child(3n - 2) {
          transform: rotate(2deg);

          @include media-breakpoint-up('lg') {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            padding-right: var(--container-padding-h);
          }
        }

        /** 2, 5, 8 */
        &:nth-child(3n - 1) {
          transform: rotate(-1deg);

          @include media-breakpoint-up('lg') {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
          }
        }

        /** 3, 6, 9 */
        &:nth-child(3n) {
          transform: rotate(1.5deg);

          @include media-breakpoint-up('xxs') {
            transform: translateX(1em) rotate(1.5deg);
          }

          @include media-breakpoint-up('md') {
            transform: translateX(17vw) rotate(1.5deg);
          }

          @include media-breakpoint-up('lg') {
            transform: rotate(-2deg);
          }

          @include media-breakpoint-up('lg') {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
          }
        }

        &:nth-child(4) {
          grid-row: 4 / 6;
          transform: rotate(-2deg);

          @include media-breakpoint-up('md') {
            transform: translateX(10%) rotate(-2deg);
          }

          @include media-breakpoint-up('lg') {
            margin-top: 5%;
            transform: rotate(-2deg);
          }
        }

        &:nth-child(5) {
          grid-row: 4 / 5;
          transform: rotate(0.5deg);
        }

        &:nth-child(6) {
          grid-row: 5 / 6;
          transform: rotate(-1.5deg);

          @include media-breakpoint-up('md') {
            transform: translateX(15%) rotate(-1.5deg);
          }

          @include media-breakpoint-up('lg') {
            transform: rotate(-1.5deg);
          }
        }

        &:nth-child(7) {
          grid-row: 7 / 9;

          @include media-breakpoint-up('md') {
            transform: translateX(7.5%) rotate(2deg);
          }

          @include media-breakpoint-up('lg') {
            margin-top: -25%;
            transform: rotate(2deg);
          }
        }

        &:nth-child(8) {
          grid-row: 7 / 8;
        }

        // &:nth-child(9) {
        //   grid-row: 8 / 9;
        // }
      }
    }

    &-button {
      margin-top: 2em;

      @include media-breakpoint-up('lg') {
        position: absolute;
        margin-top: 0;
        transform: translateY(-75%);
      }

      @include media-breakpoint-up('xxl') {
        transform: translateY(-100%);
      }
    }
  }

  &.has-2-events {
    .events-button {
      @include media-breakpoint-up('lg') {
        right: var(--container-padding-h);
      }
    }
  }
}
