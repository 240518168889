.section.clubcontacts {
  html[lang$='fr'] & {
    .section-title span:first-child::after {
      margin-right: 5px;
    }

    .section-title span:not(:first-child)::after {
      margin-left: 5px;
    }
  }

  padding: 0;

  .clubcontacts {
    &-enum.enum {
      @include media-breakpoint-up('xs') {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
      }

      @include media-breakpoint-up('md') {
        flex-wrap: nowrap;
      }

      .enum-item {
        @include media-breakpoint-up('xs') {
          width: 50%;
          padding: 0 calc(var(--container-padding-h) / 2);
        }

        @include media-breakpoint-up('md') {
          width: 33.3333%;
        }

        &:not(:first-child) {
          margin-top: calc(var(--container-padding-v) / 2);

          @include media-breakpoint-up('xs') {
            margin-top: 0;
          }
        }

        &:last-child {
          @include media-breakpoint-up('xs') {
            width: 100%;
            margin-top: calc(var(--container-padding-v) / 2);
          }

          @include media-breakpoint-between('xs', 'md') {
            .article-content {
              display: flex;
              align-items: flex-start;
              width: 100%;

              .contact-card {
                width: 50%;

                &:not(:first-child) {
                  margin-top: 0;
                  padding-left: calc(var(--container-padding-h) / 2);
                }
              }
            }
          }

          @include media-breakpoint-up('md') {
            width: 33.3333%;
            margin-top: 0;
          }
        }

        .article {
          height: 100%;
        }
      }
    }
  }

  .article.contact {
    padding: 2em;
    background-color: color('cream');
    font-size: 0.8462em;

    .article {
      &-title {
        display: inline-block;
        padding-bottom: 0.125em;
        border-bottom: 2px solid color('secondary');
        color: color('secondary');
        font-family: $font-family-narrow;
        font-weight: $font-weight-medium;
      }

      &-content {
        .p-name {
          margin-bottom: 0;
        }

        .contact-card:not(:first-child) {
          margin-top: 0.75em;
        }
      }
    }
  }
}
