.link {
  @include cursor('finger-right');

  &:focus-visible,
  &:focus-within {
    outline: none;
  }

  &.l-underline {
    display: inline-block;
    position: relative;
    color: color('teal');
    font-weight: $font-weight-semibold;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      right: 0;
      bottom: 1px;
      border-top: 2px solid color('teal');
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      &::after {
        width: 0;
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      &::after {
        transition: width 0.2s ease-out;
      }
    }
  }

  &.l-finger {
    display: inline-block;
    margin-top: 1em;
    font-family: $font-family-headings;
    line-height: 1em;

    &::before {
      @include icon('\261e', 1em, color('secondary'));

      display: inline-block;
      margin: 0 0.5em 0 0.25em;
      transform: translate(0, 0.1em);

      @media (prefers-reduced-motion: no-preference) {
        transition: color 0.25s ease-out, transform 0.25s ease-out;
      }
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      &::before {
        color: color('gold');
        transform: translate(0.25em, 0.1em);
      }
    }
  }
}
