.button {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
  outline: 1px dotted transparent;
  color: color('secondary');
  font-family: $font-family-headings;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  transition: color 0.25s ease-out, outline 0.25s ease-out;

  &:focus-visible {
    outline: none;
  }

  /** Color variants */
  --background-color: #{color('secondary')};

  &.c-ruby {
    --background-color: #{color('ruby')};
  }

  &.c-teal {
    --background-color: #{color('teal')};
  }

  &.c-peacock {
    --background-color: #{color('peacock')};
  }

  &.c-gold {
    --background-color: #{color('gold')};
  }

  &.c-cedar {
    --background-color: #{color('cedar')};
  }

  &:not(.is-disabled),
  &:not([disabled]) {
    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      @include cursor('finger-press');

      color: color('primary');

      &[type='submit'] {
        @include cursor('fingers-crossed');
      }
    }

    &.is-pressed {
      @include cursor('finger-click');
    }
  }

  &.is-disabled,
  &[disabled] {
    opacity: 0.5;
  }

  .icon {
    display: inline-block;
    font-size: 1.25em;
  }

  /** Plain */
  &.l-plain {
    display: inline-block;
    padding: 0.5em 1.5em;
    background-color: var(--background-color);
    border: 2px solid var(--background-color);
    color: color('white');
    transition: background-color 0.25s ease-out, color 0.25s ease-out;

    &:not(.is-disabled),
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible {
        background-color: transparent;
        color: var(--background-color);
      }
    }
  }

  /** Plain stamp */
  &.l-plain-stamp {
    @include stamp-background(var(--background-color));

    height: 40px;
    color: color('white');
    font-size: 0.8462rem;

    @media (prefers-reduced-motion: no-preference) {
      transition: color 0.25s ease-out, opacity 0.25s ease-out;
    }

    @include media-breakpoint-up('lg') {
      height: 48px;
    }

    &.is-disabled {
      opacity: 0.4;
    }

    &:not(.is-disabled),
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible {
        @include cursor('postmark');

        &[type='submit'] {
          @include cursor('fingers-crossed');
        }

        color: color('white');
        opacity: 0.85;
      }
    }

    /** Submit button */
    &.submit-button {
      span {
        @include underline;

        span::after {
          transform: scaleY(0.5) translateY(0.1em);
          filter: brightness(100);
        }
      }
    }

    /** Color variants */
    &.c-cream {
      color: color('primary');
    }
  }

  /** Stamp */
  &.l-stamp {
    @include stamp-background(color('sand'), color('tortilla'));

    width: 152px;
    height: 88px;
    padding: 8px 9px 8px 8px;

    @include media-breakpoint-up('xxs') {
      width: 168px;
      height: 96px;
    }

    @include media-breakpoint-up('xs') {
      width: 184px;
      height: 104px;
    }

    @include media-breakpoint-up('md') {
      width: 216px;
      height: 128px;
      padding: 12px 13px 12px 12px;
    }

    &:not(.is-disabled),
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:focus-within,
      &:focus-visible {
        @include stamp-background-hover(color('cream'));
        @include cursor('postmark');
      }
    }

    > .button-text {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 8px;
      margin: auto;
      border: 2px solid var(--background-color);
      color: color('white');
      font-size: 1.0769rem;
      line-height: $line-height-sm;
      transition: color 0.25s ease-out;

      &::after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        z-index: -1;
        background-color: var(--background-color);
      }

      .icon {
        margin-left: 0.25em;
        font-size: 1em;
        line-height: $line-height-base;
      }
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:focus-visible {
      > .button-text {
        color: color('cream');
      }
    }

    /** Color variants */
    &.c-cream {
      &:not(.is-disabled, [disabled]) {
        &:hover,
        &:focus,
        &:focus-within,
        &:focus-visible {
          @include stamp-background-hover(color('sand'));
        }
      }
    }
  }

  /** Cursor variant - Press */
  &.curs-press {
    &:not(.is-disabled),
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        @include cursor('finger-press');
      }

      &.is-pressed {
        @include cursor('finger-click');
      }
    }
  }

  /** Cursor variant - Up */
  &.curs-up {
    &:not(.is-disabled),
    &:not([disabled]) {
      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        @include cursor('finger-up');
      }
    }
  }
}
