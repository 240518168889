.container {
  width: 100%;
  max-width: var(--container-max-width);
  padding-left: var(--container-padding-h);
  padding-right: var(--container-padding-h);

  &.l-large {
    padding-left: calc(var(--container-padding-h) / 1.5);
    padding-right: calc(var(--container-padding-h) / 1.5);
  }

  &.l-narrow {
    padding-left: calc(var(--container-padding-h) * 1.5);
    padding-right: calc(var(--container-padding-h) * 1.5);
  }
}
