.template-search-stamp {
  .template-content {
    padding-top: 0;

    > .background {
      min-height: calc(100vh - var(--template-header-height) - var(--footer-height) + 24px);
      padding-top: 8px;
    }
  }

  .section {
    &-title {
      color: color('secondary');
      font-family: $font-family-narrow;
      font-size: 1em;
      font-weight: $font-weight-medium;
      text-align: left;
    }
  }
}
