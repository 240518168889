.section {
  padding: var(--container-padding-v) 0;

  &-header {
    overflow-x: hidden;

    > .background {
      display: flex;
      align-items: center;
      height: 240px;
    }

    .section {
      &-title {
        margin-bottom: 0;

        span::after {
          filter: brightness(100) drop-shadow(1px 1px rgba(color('cedar', false), 0.5));
        }
      }
    }

    &-text {
      margin-top: 1em;
      text-align: center;

      .icon {
        display: inline-block;
        margin: 0 0.15em;
        opacity: 0.5;
        transform: translateY(0.1em);
      }

      &.l-arrow {
        @include media-breakpoint-up('md') {
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            @include icon('\27a6', 4em, color('white'));

            display: inline-block;
            margin-left: 0.5em;
            opacity: 0.5;
            text-align: right;
            transform: rotate(180deg);
          }
        }
      }
    }

    &.l-cols {
      > .background {
        @include media-breakpoint-down('md') {
          height: 304px;
        }
      }

      @include media-breakpoint-up('md') {
        .container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .section {
          &-title {
            padding-right: var(--container-padding-h);
            text-align: left;
            white-space: nowrap;
          }

          &-header {
            &-text {
              margin-top: 0;
              text-align: right;
            }
          }
        }
      }
    }

    &.l-stamp-outline {
      position: relative;
      z-index: 2;
      margin-top: -8px;

      & + .background {
        margin-top: -8px;
      }
    }
  }

  &-title {
    text-align: center;

    @include underline;
  }

  &-subtitle {
    font-family: $font-family-narrow;
    font-weight: $font-weight-medium;
  }

  &-content {
    padding: var(--container-padding-v) 0;

    &.is-loading {
      overflow: hidden;
      /* stylelint-disable-next-line declaration-no-important */
      min-height: 18rem !important;
    }
  }

  &-footer {
    //
  }

  &.flexible {
    .flexible-content {
      .flexible-text {
        a {
          /** Embedded here instead of ./sections/_flexible.scss to avoid a build error */
          @include cursor('finger-right');
        }
      }
    }
  }
}
