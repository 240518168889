@font-face {
  font-family: 'icomoon'; /* stylelint-disable-line font-family-name-quotes */
  font-weight: normal;
  font-style: normal;
  src: url('icomoon.eot?aph1');

  /* prettier-ignore */
  src:
    url('icomoon.eot?aph1#iefix') format('embedded-opentype'),
    url('icomoon.woff2?aph1') format('woff2'),
    url('icomoon.ttf?aph1') format('truetype'),
    url('icomoon.woff?aph1') format('woff'),
    url('icomoon.svg?aph1#icomoon') format('svg');
}
