.collapsable .collapsable-content {
  overflow: hidden;
  transition: height 0.35s ease, min-height 0.35s ease;

  &.is-collapsed {
    height: 0 !important;
    min-height: 0 !important;

    @media only print {
      height: auto !important;
      min-height: auto !important;
    }
  }
}
