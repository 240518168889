.layout.header {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: var(--header-height);

  &.is-front-page {
    & + .template > *:first-child,
    & + :not(.template) {
      padding-top: var(--header-height);
    }

    @include media-breakpoint-up('lg') {
      height: var(--header-height-with-logo);

      & + .template > *:first-child,
      & + :not(.template) {
        padding-top: var(--header-height-with-logo);
      }
    }
  }

  > .container {
    @include media-breakpoint-up('xl') {
      padding-left: calc(var(--container-padding-h) * 1.5);
      padding-right: calc(var(--container-padding-h) * 1.5);
    }
  }

  .header {
    &-content {
      position: relative;
    }

    &-logo.logo {
      margin: 0;
      font-size: 1rem;
      line-height: $line-height-base;

      .logo {
        &-picto,
        &-image {
          display: inline-block;
          vertical-align: top;

          img {
            mix-blend-mode: multiply;
          }
        }

        &-button {
          transform: translateY(-8px);

          &.l-picto {
            position: fixed;
            top: 0;
            z-index: 101;

            @include media-breakpoint-up('md') {
              margin-left: 20px;
            }

            @include media-breakpoint-up('lg') {
              margin-left: 44px;
            }
          }

          &.l-image {
            position: relative;
            z-index: 102;
          }

          &:hover,
          &:focus,
          &:focus-within,
          &:focus-visible {
            .logo-picto {
              @include stamp-background-hover(color('cream'));
            }
          }
        }

        &-picto {
          @include stamp-background(color('sand'), color('tortilla'));

          width: 64px;
          height: 56px;
          padding: 10px 8px 0 8px;

          @include media-breakpoint-up('lg') {
            width: 72px;
            height: 64px;
          }
        }

        &-image {
          @include stamp-background(color('cream'), color('tortilla'));

          width: 160px;
          height: 168px;
          padding: 18px 13px 10px 12px;
          text-align: center;

          @media (prefers-reduced-motion: no-preference) {
            transition: transform 0.25s ease-out;
          }

          @include media-breakpoint-down('lg') {
            display: none;
          }
        }
      }
    }

    &-nav.nav {
      @include media-breakpoint-up('sm') {
        position: fixed;
        top: -24px;
        margin-left: 74px;
      }

      @include media-breakpoint-up('md') {
        margin-left: 94px;
      }

      @include media-breakpoint-up('lg') {
        top: -16px;
        margin-left: 175px;
      }

      @include media-breakpoint-up('xl') {
        margin-left: 180px;
      }

      .nav {
        &-primary,
        &-overlay .menu {
          padding: 0;
          margin: 0;
          list-style: none;
        }

        &-toggle-button {
          position: fixed;
          top: 0;
          right: var(--container-padding-h);
          z-index: 101;
          width: 64px;
          height: 48px;
          text-align: center;

          @include media-breakpoint-up('sm') {
            display: none;
          }

          &[aria-expanded='false'] {
            .icon[class*='icon-times'] {
              display: none;
            }

            & + .nav-primary {
              transform: translateX(-100vw);
            }
          }

          &[aria-expanded='true'] {
            .icon[class*='icon-bars'] {
              display: none;
            }
          }

          .icon {
            font-size: 24px;
          }
        }

        &-primary {
          display: flex;

          @include media-breakpoint-down('sm') {
            flex-flow: column wrap;
            align-content: center;
            align-items: center;
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 100;
            height: 100vh;
            /* stylelint-disable-next-line value-no-vendor-prefix */
            height: -webkit-fill-available;
            padding-top: 3vh;
            background: radial-gradient(lighten(color('teal', false), 10%) 0%, darken(color('teal', false), 10%) 200%);

            @media (prefers-reduced-motion: no-preference) {
              transition: transform 0.25s ease-out;
            }
          }

          > li {
            width: 152px;
            position: relative;

            @include media-breakpoint-down('sm') {
              margin: 3vh;
            }

            @include media-breakpoint-up('sm') {
              width: 120px;
            }

            @include media-breakpoint-up('md') {
              width: 152px;
            }

            @include media-breakpoint-up('lg') {
              width: 168px;
            }

            @include media-breakpoint-up('xl') {
              width: 216px;
            }

            &:not(:first-child) {
              @include media-breakpoint-up('sm') {
                margin-left: 10px;
              }

              @include media-breakpoint-up('lg') {
                margin-left: 15px;
              }

              @include media-breakpoint-up('xl') {
                margin-left: 20px;
              }

              @include media-breakpoint-up('xxl') {
                margin-left: 36px;
              }

              @include media-breakpoint-up('xxxl') {
                margin-left: 56px;
              }
            }
          }
        }

        &-item {
          display: inline-block;

          @include media-breakpoint-up('sm') {
            position: absolute;
          }

          @media (prefers-reduced-motion: no-preference) {
            &.is-closing .nav-overlay {
              opacity: 0;
            }
          }
        }

        &-button {
          width: 152px;
          height: 72px;

          @include media-breakpoint-up('sm') {
            width: 120px;
          }

          @include media-breakpoint-up('md') {
            width: 152px;
          }

          @include media-breakpoint-up('lg') {
            width: 168px;
          }

          @include media-breakpoint-up('xl') {
            width: 216px;
          }

          > .button-text {
            align-items: flex-end;
            padding-bottom: 14px;
            font-size: 18px;

            @include media-breakpoint-up('sm') {
              padding-bottom: 8px;
              font-size: 16px;
            }

            @include media-breakpoint-up('md') {
              padding-bottom: 6px;
              font-size: 18px;
            }

            @include media-breakpoint-up('lg') {
              font-size: 20px;
            }

            @include media-breakpoint-up('xl') {
              font-size: 25px;
            }
          }

          .icon[class*='icon-lock'] {
            line-height: 1.55em;

            @include media-breakpoint-down('md') {
              margin-left: 0.1em;
            }
          }
        }

        &-overlay {
          @include media-breakpoint-up('sm') {
            margin-top: 10px;
          }

          @media (prefers-reduced-motion: no-preference) {
            animation: appear 0.25s ease-in 0s alternate 1 backwards;
            transition: opacity 0.25s ease-in;

            &.is-closing {
              opacity: 0;
            }
          }

          &:not(.l-members) {
            @include stamp-background(color('cream'), color('tortilla'));

            width: 216px;
            height: 136px;
            padding: 8px 9px 8px 8px;
            font-family: $font-family-narrow;
            font-weight: $font-weight-normal;

            @include media-breakpoint-down('sm') {
              position: absolute;
              top: 16px;
              left: 16px;
              z-index: 10;
            }

            @include media-breakpoint-up('md') {
              width: 240px;
              height: 144px;
              padding: 12px 13px 12px 12px;
            }

            @include media-breakpoint-up('lg') {
              width: 272px;
              height: 160px;
            }
          }

          .menu {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;
            padding: 2px;
            margin: auto;
            border: 2px solid color('primary');
            font-size: 16px;
            line-height: 27.5px;

            @include media-breakpoint-up('md') {
              font-size: 18px;
            }

            @include media-breakpoint-up('lg') {
              font-size: 21px;
              line-height: 31.5px;
            }

            [role='menuitem'] {
              &:not(:last-child)::after {
                content: '';
                display: block;
                width: 100%;
                height: 0;
                border-bottom: 1px solid color('primary');
                opacity: 0.3;
              }

              .link {
                display: block;
                padding: 0 8px;
                color: color('primary');
                transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
              }

              &:hover,
              &:focus,
              &:focus-within,
              &:focus-visible {
                outline: none;

                .link {
                  background-color: color('primary');
                  outline: none;

                  @include media-breakpoint-down('sm') {
                    /* stylelint-disable-next-line declaration-no-important -- Override all the color layout variants */
                    background-color: transparent !important;
                  }

                  @include media-breakpoint-up('sm') {
                    color: color('white');
                  }
                }
              }
            }

            /** Color: ruby */
            &.c-ruby {
              border-color: color('ruby');

              [role='menuitem'] {
                &:not(:last-child)::after {
                  border-color: color('ruby');
                }

                &:hover,
                &:focus,
                &:focus-within,
                &:focus-visible {
                  .link {
                    background-color: color('ruby');
                  }
                }
              }
            }

            /** Color: teal */
            &.c-teal {
              border-color: color('teal');

              [role='menuitem'] {
                &:not(:last-child)::after {
                  border-color: color('teal');
                }

                &:hover,
                &:focus,
                &:focus-within,
                &:focus-visible {
                  .link {
                    background-color: color('teal');
                  }
                }
              }
            }

            /** Color: peacock */
            &.c-peacock {
              border-color: color('peacock');

              [role='menuitem'] {
                &:not(:last-child)::after {
                  border-color: color('peacock');
                }

                &:hover,
                &:focus,
                &:focus-within,
                &:focus-visible {
                  .link {
                    background-color: color('peacock');
                  }
                }
              }
            }

            /** Color: gold */
            &.c-gold {
              border-color: color('gold');

              [role='menuitem'] {
                &:not(:last-child)::after {
                  border-color: color('gold');
                }

                &:hover,
                &:focus,
                &:focus-within,
                &:focus-visible {
                  .link {
                    background-color: color('gold');
                  }
                }
              }
            }
          }

          /** Overlay: Le club */
          &.l-club {
            .menu {
              line-height: 27.5px;

              @include media-breakpoint-up('lg') {
                line-height: 31.5px;
              }
            }
          }

          /** Overlay: Actualités */
          &.l-news {
            width: 216px;
            height: 80px;

            @include media-breakpoint-up('md') {
              width: 248px;
              height: 88px;
            }

            @include media-breakpoint-up('lg') {
              width: 280px;
              height: 96px;
            }

            .menu {
              line-height: 27.5px;

              @include media-breakpoint-up('lg') {
                line-height: 32px;
              }
            }
          }

          /** Overlay: Recherche */
          &.l-search {
            width: 216px;
            height: 80px;

            @include media-breakpoint-up('md') {
              width: 240px;
              height: 88px;
            }

            @include media-breakpoint-up('lg') {
              width: 272px;
              height: 96px;
            }

            .menu {
              line-height: 27.5px;

              @include media-breakpoint-up('lg') {
                line-height: 32px;
              }
            }
          }

          /** Overlay: Membres */
          &.l-members {
            @include media-breakpoint-down('sm') {
              position: absolute;
              bottom: 14px;
              left: 16px;
              z-index: 10;
            }

            .form.login {
              width: 224px;
              height: 128px;
              padding: 8px 9px 8px 8px;
              font-size: 16px;

              @include media-breakpoint-up('md') {
                padding: 12px 13px 12px 12px;
              }

              &.has-errors,
              &.has-status {
                height: 184px;
              }
            }

            @include media-breakpoint-up('sm') {
              transform: translateX(-104px);
            }

            @include media-breakpoint-up('md') {
              transform: translateX(-88px);

              .form.login {
                width: 240px;
                height: 144px;
                font-size: 18px;

                &.has-errors,
                &.has-status {
                  height: 200px;
                }
              }
            }

            @include media-breakpoint-up('lg') {
              transform: translateX(-104px);

              .form.login {
                width: 272px;
                height: 160px;
                font-size: 21px;

                &.has-errors,
                &.has-status {
                  height: 232px;
                }
              }
            }

            @include media-breakpoint-up('xl') {
              transform: translateX(-56px);
            }

            @include media-breakpoint-up('xxl') {
              transform: none;
            }

            .menu {
              line-height: 27.5px;

              @include media-breakpoint-up('lg') {
                line-height: 31.5px;
              }
            }
          }
        }
      }
    }
  }
}
