.template-news-novelties {
  overflow: hidden;

  .novelties-enum.enum {
    .enum {
      &-item {
        &:nth-child(even) {
          background-color: rgba(color('white', false), 0.75);
        }

        &:not(:last-child)::after {
          content: '';

          @include hatched-background;
        }
      }
    }
  }
}
