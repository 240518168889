.modal {
  @include scrollbar;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 109;
  overflow: scroll;
  overflow-x: hidden;
  padding: 2em 0;
  background: radial-gradient(
    rgba(lighten(color('peacock', false), 10%), 0.5) 0%,
    darken(color('peacock', false), 10%) 200%
  );

  @include media-breakpoint-up('md', 'height') {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 0;
  }

  @media (prefers-reduced-motion: no-preference) {
    animation: appear 0.25s ease-in 0s alternate 1 backwards;
    transition: opacity 0.25s ease-in;

    &.is-closing {
      opacity: 0;
    }
  }

  &-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    outline: none;

    .link {
      display: block;
      margin: 1rem auto 0 auto;
      color: color('white');
      font-size: 1.174em;
      text-align: center;

      &::before {
        color: color('white');
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        &::before {
          color: color('teal');
        }
      }
    }
  }

  &-title {
    max-width: 540px;
    padding-right: 1em;
    margin: 0 auto 1rem auto;
    color: color('white');
    text-align: center;

    @include media-breakpoint-up('xxs') {
      padding-right: 0;
    }

    @include media-breakpoint-up('md', 'height') {
      margin-bottom: 1em;
    }
  }

  &-close-button {
    position: absolute;
    top: 1em;
    right: 1em;
    color: color('white');

    @include media-breakpoint-up('sm') {
      top: 2em;
      right: 2em;
    }

    &:hover,
    &:focus,
    &:focus-visible,
    &:focus-within {
      .icon {
        color: color('teal');
      }
    }
  }
}
