.template-search-members {
  .section.membersearches {
    margin-top: -8px;

    > .background {
      min-height: calc(100vh - var(--template-header-height) - var(--footer-height) + 24px);
    }

    .section-content {
      min-height: calc(100vh - var(--template-header-height) - var(--footer-nav-height) - var(--footer-height) + 24px);
    }
  }

  // .section.flexible + .section.membersearches {
  //   > .background {
  //     min-height: 0;

  //     &::before {
  //       content: '';

  //       @include hatched-background;
  //     }
  //   }

  //   .section-content {
  //     min-height: 0;
  //   }
  // }
}
