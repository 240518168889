/**
 * Changes the mouse cursor
 */
@mixin cursor($type) {
  @if $type == 'postmark' {
    /**
     * Don't use postmark near the edges of the viewport as it will not work when overlapping the edges
     * @see https://chromestatus.com/feature/5825971391299584
     */
    cursor: url('../../images/cursors/postmark.svg') 85 37, pointer;
  } @else if $type == 'finger-press' {
    cursor: url('../../images/cursors/finger-press.svg') 24 8, pointer;
  } @else if $type == 'finger-click' {
    cursor: url('../../images/cursors/finger-click.svg') 24 8, pointer;
  } @else if $type == 'finger-right' {
    cursor: url('../../images/cursors/finger-right.svg') 30 8, pointer;
  } @else if $type == 'fingers-crossed' {
    cursor: url('../../images/cursors/fingers-crossed.svg') 16 2, pointer;
  } @else if $type == 'fingers-up' {
    cursor: url('../../images/cursors/fingers-up.svg') 18 0, pointer;
  } @else if $type == 'text' {
    cursor: url('../../images/cursors/text.svg') 8 2, pointer;
  } @else {
    cursor: url('../../images/cursors/finger-up.svg') 6 1, pointer;
  }
}
