/**
 * Return a color in the $colors variable
 *
 * @param string $color  The color name
 * @param bool $css-vars  Wether to return a variable or the color value
 */
@function color($color, $return-var: $css-vars) {
  /** Add -- before the variable name if it has been omitted */
  @if str-slice($color, 0, 2) != '--' {
    $color: '--#{$color}';
  }

  @if $return-var {
    @return unquote('var(' + $color + ')');
  } @else {
    @return map-get($colors, $color);
  }
}

/**
 * Return a variable in the $variables variable
 *
 * @param string $variable  The color name
 */
@function var($variable) {
  /** Add -- before the variable name if it has been omitted */
  @if str-slice($variable, 0, 2) != '--' {
    $variable: '--#{$variable}';
  }

  @if $css-vars {
    @return unquote('var(' + $variable + ')');
  } @else {
    @return map-get($variables, $variable);
  }
}
