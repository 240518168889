.beegreen {
  @keyframes beegreen {
    0% {
      fill: #72be1e;
    }

    5% {
      fill: #fff;
    }

    100% {
      fill: #fff;
    }
  }

  @keyframes beegreen-eye {
    0% {
      opacity: 0;
    }

    1% {
      opacity: 1;
    }

    2% {
      opacity: 0;
    }

    3% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .bee {
      .eye {
        animation: beegreen-eye 10s infinite alternate backwards 1.2s;
      }
    }

    .letter {
      path {
        animation: beegreen 10s infinite alternate backwards;
      }

      &:nth-child(2) path {
        animation-delay: 0.1s;
      }

      &:nth-child(3) path {
        animation-delay: 0.2s;
      }

      &:nth-child(5) path {
        animation-delay: 0.4s;
      }

      &:nth-child(6) path {
        animation-delay: 0.5s;
      }

      &:nth-child(7) path {
        animation-delay: 0.6s;
      }

      &:nth-child(8) path {
        animation-delay: 0.7s;
      }

      &:nth-child(9) path {
        animation-delay: 0.8s;
      }
    }
  }
}
