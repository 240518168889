.background {
  &.l-stamp-outline {
    --background-color: #{color('primary')};

    @include stamp-background-large(var(--background-color), color('tortilla'), true);

    color: color('white');
    text-shadow: 1px 1px rgba(color('cedar', false), 0.5);

    /** Color variants */
    &.c-ruby {
      --background-color: #{color('ruby')};
    }

    &.c-teal {
      --background-color: #{color('teal')};
    }

    &.c-peacock {
      --background-color: #{color('peacock')};
    }

    &.c-gold {
      --background-color: #{color('gold')};
    }

    &.c-cedar {
      --background-color: #{color('cedar')};
    }

    .template-header &::before {
      margin-top: -8px;
    }
  }

  &.l-stamps {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: radial-gradient(
      ellipse at 50% calc(50% + 120px),
      rgba(color('white', false), 0.85) 0%,
      rgba(lighten(color('gold', false), 20%), 0.1) 65%,
      rgba(darken(color('gold', false), 5%), 0.15) 160%
    );

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: 1000vw;
      height: 1000vw;
      // background-color: color('teal');
      background-repeat: no-repeat;
      background-size: 30.9505vw 17.2005vw;
      mix-blend-mode: multiply;
    }

    &::before {
      // prettier-ignore
      background-image:
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg');
      // prettier-ignore
      background-position:
        50% 50%, // center
        calc(50% + 25vw) calc(50% - 62vw), // top left
        calc(50% + 90vw) calc(50% - 25vw), // top right
        calc(50% - 25vw) calc(50% + 62vw), // bottom right
        calc(50% - 90vw) calc(50% + 25vw); // bottom left

      opacity: 0.25;
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &::after {
      // prettier-ignore
      background-image:
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg'),
        url('../../images/stamp.svg');
      // prettier-ignore
      background-position:
        calc(50% - 52.5vw) calc(50% + 25vw), // top left
        calc(50% + 25vw) calc(50% - 42vw), // top right
        calc(50% + 65vw) calc(50% - 10vw), // bottom right
        calc(50% + 10vw) calc(50% + 42vw); // bottom left

      opacity: 0.35;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &.l-pale-gradient {
    @include pale-gradient-background('gold');

    position: relative;
    z-index: 1;
    overflow: hidden;

    /** Color variants */
    &.c-ruby {
      @include pale-gradient-background('ruby');
    }

    &.c-teal {
      @include pale-gradient-background('teal');
    }

    &.c-peacock {
      @include pale-gradient-background('peacock');
    }

    &.c-gold {
      @include pale-gradient-background('gold');
    }

    &.c-cedar {
      @include pale-gradient-background('cedar');
    }

    &.c-cream {
      @include pale-gradient-background('cream');
    }
  }

  &.l-paper {
    background: url('../../images/bg-paper.jpg') repeat fixed;
  }

  &.l-watercolor,
  &.l-watercolor-paper,
  &.l-watercolor-paper-orange {
    position: relative;
    z-index: 1;

    &.l-watercolor-paper::before,
    &.l-watercolor-paper-orange::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    &.l-watercolor-paper::before,
    &.l-watercolor-paper-orange::before {
      background: url('../../images/bg-paper.jpg') repeat fixed;
    }

    &::after {
      background: url('../../images/bg-watercolor.jpg') no-repeat center top;
      background-size: 100% auto;
      opacity: 0.3;

      @media (orientation: portrait) {
        background-size: cover;
      }
    }

    &.l-watercolor-paper-orange::after {
      background: url('../../images/bg-watercolor-orange.jpg') no-repeat center top;
      opacity: 0.4;
    }
  }
}
