/**
 * Form cards width and font size
 *
 * `$form-card-width` and `$form-card-font-size` should have the same breakpoints
 */
$form-card-width: (
  'null': calc(100vw - 4em),
  'sm': calc(100vw - 4em),
  'md': 905px,
);
$form-card-font-size: (
  'null': 0.9167em,
  'sm': 2.5vw,
  'md': 24px,
);

.form.contact {
  display: flex;
  position: relative;
  margin: 0 auto;
  transform: rotate(-1deg);

  @each $breakpoint, $value in $form-card-width {
    @if $breakpoint == 'null' {
      width: #{$value};
      font-size: map-get($form-card-font-size, $breakpoint);
    } @else {
      @include media-breakpoint-up($breakpoint) {
        width: #{$value};
        font-size: map-get($form-card-font-size, $breakpoint);
      }
    }
  }

  /** Aspect ratio */
  &::before {
    content: '';
    display: block;
    padding-top: 48.066%;
  }

  /** Shadow */
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
    bottom: 10px;
    left: 15px;
    z-index: -1;
    box-shadow: 0 0 45px -30px color('black');
  }

  .form {
    &-wrap {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 1.5em;
      background-color: color('white');

      @include media-breakpoint-up('sm') {
        flex-direction: row;
      }

      &::before,
      &::after {
        @include hatched-background;

        content: '';
        position: absolute;
        left: 0;
        right: 0;
        height: 12px;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    &-section {
      @include media-breakpoint-up('sm') {
        width: 50%;

        &:first-child {
          order: 2;
          padding-top: 20%;
          padding-left: 1.5em;
          border-left: 1px solid color('tortilla');
        }

        &:last-child {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding-right: 1.5em;
        }
      }
    }

    &-field {
      position: relative;

      label {
        @include visually-hidden;
      }

      input,
      textarea {
        font-weight: $font-weight-medium;
      }

      &.text {
        input {
          padding: 0.75em 0 0.25em 0;
          border-bottom: 1px solid color('tortilla');
        }

        &:last-child {
          padding-bottom: 2px;
          border-bottom: 3px solid color('tortilla');

          .form-field-error {
            bottom: -2px;
          }
        }
      }

      &.textarea {
        flex-grow: 1;

        .form-field-control {
          display: flex;
          align-items: stretch;
          height: 9.5em;
          margin-top: 1.5em;

          @include media-breakpoint-up('sm') {
            height: 100%;
            margin-top: 0;
          }
        }

        textarea {
          min-width: 100%;
          max-width: 100%;
          height: 100%;
          min-height: 100%;
          max-height: 100%;

          @include scrollbar;
        }
      }

      &-error {
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: 0;
        font-family: $font-family-base;
        font-weight: $font-weight-normal;
        font-size: 0.6923em;
        color: color('ruby');
        transform: translateY(calc(100% - 0.1em));

        @media (prefers-reduced-motion: no-preference) {
          animation: appear 0.25s ease-out 0s 1 alternate backwards;
        }
      }
    }

    &-status,
    &-error {
      margin: 0;
      font-size: 0.9167em;
      font-weight: $font-weight-semibold;

      @media (prefers-reduced-motion: no-preference) {
        animation: appear 0.25s ease-out 0s 1 alternate backwards;
      }

      &:not(:empty) {
        padding-top: 1.5em;

        &::before {
          content: '';
          display: block;
          width: 100%;
          border-top: 1px solid color('tortilla');
        }
      }
    }

    &-status {
      color: color('teal');
    }

    &-error {
      color: color('ruby');
    }

    &-submit {
      position: relative;
      margin: 1em 0;
      text-align: right;

      @include media-breakpoint-up('sm') {
        position: absolute;
        top: calc(1em + 12px);
        right: 1em;
        z-index: 1;
        margin: 0;
      }

      .submit-button {
        width: 136px;
        height: 64px;
        font-size: 18px;

        @include media-breakpoint-up('sm') {
          width: 160px;
          height: 72px;
          font-size: 22px;
          line-height: $line-height-sm;
        }

        @include media-breakpoint-up('md') {
          width: 184px;
          height: 80px;
          font-size: 24px;
          line-height: $line-height-sm;
        }
      }

      .datetime {
        position: absolute;
        top: 30px;
        right: 132px;
        z-index: 2;
        font-size: 0.9231rem;

        @include media-breakpoint-up('sm') {
          top: 40px;
          right: 188px;
        }

        @media (prefers-reduced-motion: no-preference) {
          animation: postmark 0.3333s ease-in 0s 1 alternate backwards;
        }
      }

      .sent-message {
        top: 64px;
        right: 1em;
        position: absolute;
        z-index: 2;
        color: color('secondary');
        font-family: $font-family-narrow;
        font-size: 0.8462rem;
        font-weight: $font-weight-normal;
        white-space: nowrap;

        @include media-breakpoint-up('sm') {
          right: 0.25em;
          top: 88px;
        }

        @media (prefers-reduced-motion: no-preference) {
          animation: postmark 0.3333s ease-in 0.25s 1 alternate backwards;
        }

        span {
          display: inline-block;
          position: relative;
          width: 7.75em;
          text-align: center;
          transform: rotate(-2deg);

          &::before {
            @include icon('\e907', 7.75em, color('tortilla'));

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% - 0.075em));
            opacity: 0.7;
          }
        }
      }
    }
  }
}
